import React from 'react';

import { type SbBlokData, storyblokEditable } from '@storyblok/react';
import NextLink from 'next/link';

import { type BrandReleases } from 'api';
import { Logger, ReleaseLite } from 'api/types';
import HtLink from 'components/common-n4/ht-link';
import ImageWrapper from 'components/common-n4/image';
import { useApiData } from 'hooks';
import logger from 'lib/logger';
import { loadBrandReleases } from 'lib/storyblok';
import { normalizeUrl } from 'lib/utils';

const log: Logger = logger({ category: 'brand-releases' });
const origin = 'bloks/n4/brand-releases';

interface Blok extends SbBlokData {
  brand: number;
}

interface StoryContent {
  blocks: SbBlokData[];
}

interface Story {
  content: StoryContent;
  cv: number;
}

interface BrandReleasesProps {
  blok: Blok;
  story: Story;
}

const BrandReleasesBlok: React.FC<BrandReleasesProps> = ({ blok, story }) => {
  const { api } = useApiData();
  const [brandReleases, setBrandReleases] = React.useState<BrandReleases | undefined>(
    blok.brand && api.brandReleases ? api.brandReleases[blok.brand] : undefined
  );
  const { brand, years } = brandReleases || { years: {} };

  React.useEffect(() => {
    if (!blok?.brand) {
      setBrandReleases(undefined);
    }
    const fn = async () => {
      await loadBrandReleases({ story, api, log });
      if (api.brandReleases?.[blok.brand]) {
        setBrandReleases(api.brandReleases[blok.brand]);
      }
    };
    fn();
  }, [story, blok, api]);

  if (!brand || !Object.keys(years || {}).length) {
    return null;
  }

  return (
    <section className="brand-releases my-10 w-full max-w-[640px]" {...storyblokEditable(blok)}>
      {brand.image && <ImageWrapper image={brand.image} imgProps={{ style: { width: 200, height: 'auto' } }} origin="brand-releases" />}
      {brand.path ? (
        <NextLink prefetch={false} className="mb-2 mt-5 block text-[1.8em] font-bold" href={normalizeUrl({ url: `/hearing-aids/${brand.path}`, origin })}>
          {brand.name} Hearing Aids
        </NextLink>
      ) : (
        <div>{brand.name}</div>
      )}
      <ul>
        {Object.entries(years as Record<string, ReleaseLite[]>)
          .sort(([a], [b]) => Number(b) - Number(a))
          .map(([year, releases]) => (
            <li className="pb-1" key={year}>
              <span className="font-bold">{year}: </span>
              <span>
                {releases.map((release, idx) => {
                  const isArchived = release.path?.includes('/archived/');
                  const content = (
                    <>
                      {release.name}
                      {idx < releases.length - 1 && ','} {/* Add a comma after the name if it's not the last item */}
                    </>
                  );

                  return !release.path || isArchived ? (
                    <span key={release.id}>{content}</span>
                  ) : (
                    <HtLink
                      key={release.id}
                      href={normalizeUrl({ url: release.path, origin: release.slug })}
                      // className={idx < releases.length - 1 ? `mr-2 after:content-[","]` : ''}
                    >
                      {content}
                    </HtLink>
                  );
                })}
              </span>
            </li>
          ))}
      </ul>
    </section>
  );
};

export default BrandReleasesBlok;
