import React from 'react';

import { storyblokEditable, type SbBlokData } from '@storyblok/react';

import { CircleProgress } from 'components/bloks/n4/images/circle_progress';
import SvgIcon from 'components/ui/svg_icon';
import { tw, formatSecondsToTime } from 'lib/utils';
import type { AssetStoryblok } from 'types/storyblok-component-types';

interface Blok extends SbBlokData {
  file: AssetStoryblok;
  caption?: string;
}

interface AudioFileProps {
  blok: Blok;
}

interface AudioPlayerProps {
  isPlaying?: boolean;
  progress: number;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ isPlaying, progress }) => (
  <button type="button" aria-label={isPlaying ? 'play audio' : 'pause audio'} className="relative">
    <CircleProgress size="48" progress={progress * 100} isStatic />
    <figure className="absolute-center">
      <SvgIcon icon={isPlaying ? 'pause' : 'play'} className="ml-[8px] h-[16px]" />
    </figure>
  </button>
);

const Audio = ({ url, caption }: { url: string; caption?: string }) => {
  const audioRef = React.useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [currentTime, setCurrentTime] = React.useState('0:00');
  const [duration, setDuration] = React.useState('0:00');
  const [progress, setProgress] = React.useState(0);

  const updateProgress = React.useCallback(() => {
    if (!audioRef.current) return;
    setCurrentTime(formatSecondsToTime(audioRef.current.currentTime));
    setProgress(audioRef.current.currentTime / audioRef.current.duration);
  }, []);

  const handleMetadataLoaded = () => {
    if (audioRef.current && audioRef.current.duration) {
      setDuration(formatSecondsToTime(audioRef.current.duration));
    }
  };

  const handlePlay = () => {
    if (!audioRef.current) return;
    audioRef.current.play();
    setIsPlaying(true);
    const timeFormatted = formatSecondsToTime(audioRef.current.currentTime);
    setCurrentTime(timeFormatted);
  };

  const handlePause = React.useCallback(() => {
    if (!audioRef.current) return;

    if (isPlaying) audioRef.current.pause();

    setIsPlaying(false);
  }, [isPlaying]);

  React.useEffect(() => {
    const audioEl = audioRef.current;

    if (audioEl) {
      audioEl.addEventListener('ended', handlePause);
      audioEl.addEventListener('pause', handlePause);
      audioEl.addEventListener('timeupdate', updateProgress);
    }

    return () => {
      if (audioEl) {
        audioEl.removeEventListener('ended', handlePause);
        audioEl.removeEventListener('pause', handlePause);
        audioEl.removeEventListener('pause', updateProgress);
      }
    };
  }, [handlePause, updateProgress]);

  if (!url) return null;

  return (
    <div
      className={tw(
        'boxShadow-sm flex w-[318px] items-start justify-between gap-x-[20px] gap-y-[16px] rounded-[20px] border border-navy-10',
        'my-3 cursor-pointer bg-white px-[16px] py-[20px] text-navy shadow-sm lg:flex-row lg:items-center lg:px-[24px] lg:py-[24px]'
      )}
      onClick={isPlaying ? handlePause : handlePlay}
    >
      <div className="ml-[3px] w-full">
        {caption && <div className="text-[16px] font-[500] leading-[28px] tracking-[-0.6px] text-navy lg:text-[20px]">{caption}</div>}
        <div className="mt-[4px] text-[14px] leading-[19.6px] tracking-[-0.42px] text-[#545454]">
          {currentTime} / {duration}
        </div>
      </div>
      <div className="flex items-center">
        <AudioPlayer isPlaying={isPlaying} progress={progress} />
      </div>
      <audio ref={audioRef} src={url} preload="none" onLoadedMetadata={handleMetadataLoaded} />
    </div>
  );
};

const AudioFile: React.FC<AudioFileProps> = ({ blok }) => {
  const { file, caption } = blok;
  const { filename } = file;

  return (
    <section className="n4-audio-file" {...storyblokEditable(blok)}>
      <Audio url={filename!} caption={caption} />
    </section>
  );
};

export default AudioFile;
