import React from 'react';

import { getAb, recordAbEvent, type AbButtonPosition, type AbLinkPosition, type TestName } from 'lib/ab-tests';

import { useOncePerPage } from './use-once-per-page';

interface UseAbProps {
  testName: TestName;
  uuid: string;
  position?: AbButtonPosition | AbLinkPosition;
  event: string;
  origin: string;
  skip: boolean;
  clicked: number;
  fallbackText: string; // text to show if we can't get a segment
  forceText?: string; // ignore a/b test and just show this
  forceCta?: string;
  eventProperties?: Record<string, string | number>;
  substitutions?: Record<string, string>;
}

export const useAb = ({
  testName,
  uuid,
  position,
  event,
  origin,
  skip,
  clicked,
  fallbackText = '',
  forceCta = '',
  forceText = '',
  eventProperties,
  substitutions = {},
}: UseAbProps) => {
  const useOnce = useOncePerPage();
  const [state, setState] = React.useState({ event, testName, position: position || testName, origin, text: '', cta: '', segment: -2 });

  if (useOnce({ item: `${testName}-${uuid}-impression`, skip: state.segment < 0 || skip || forceText })) {
    recordAbEvent({
      ...state,
      properties: {
        action: 'impression',
        ...eventProperties,
      },
    });
  }

  if (useOnce({ item: `${testName}-${uuid}-click`, skip: state.segment < 0 || skip || clicked !== 1 || forceText })) {
    recordAbEvent({
      ...state,
      properties: {
        action: 'click',
        ...eventProperties,
      },
    });
  }

  React.useEffect(() => {
    if (forceText) {
      setState((prev) => ({ ...prev, cta: forceCta, text: forceText }));
    } else {
      const ab = getAb(testName, substitutions);
      if (ab.segment < 0 || ab.segment > 100) {
        setState((prev) => ({ ...prev, ...ab, text: fallbackText }));
      } else {
        setState((prev) => ({ ...prev, ...ab }));
      }
    }
  }, [testName, substitutions, fallbackText, forceCta, forceText]);

  return { cta: state.cta, text: state.text };
};
