import * as React from 'react';

import { storyblokEditable } from '@storyblok/react';

import { HEARING_AID_MODEL_SCORE_TOTAL } from 'components/common/constants.js';
import { CircleScore } from 'components/common-n4/circle-score';
import HtLink from 'components/common-n4/ht-link';
import ImageWrapper from 'components/common-n4/image';
import SvgIcon from 'components/ui/svg_icon';
import Currency from 'components/widgets/currency';
import { useIsMediumOrMobileView } from 'hooks/use_is_mobile_view';
import logger from 'lib/logger';
import { normalizeUrl } from 'lib/utils';

import styles from '../nav/product.module.scss';

const log = logger({ category: 'nav/Product' });

const NavProduct = ({ blok }) => {
  log.debug('blok: %o', blok);
  const product = blok;
  const { slug } = product;

  const isMediumOrMobileView = useIsMediumOrMobileView();

  /*
  // Note: this is for storyblok live editing; that's the only
  // time the slug should change on the fly.
  React.useEffect(() => {
    if (product && product.slug === slug) {
      return;
    }
    if (models[slug]) {
      setProduct(models[slug]);
      return;
    }
    const fn = async () => {
      const results = await fetchApi({
        path: 'releases/models',
        variables: { slugs: [slug] },
        fallback: {},
        origin: 'nav-expanded/product',
      });
      log.info('ff change %s -> %o', slug, results);
      const ff = Object.values(results)[0];
      if (ff && !ff.error) {
        setProduct(ff);
      }
    };
    fn();
  }, [product, slug, models]);
  */

  if (!product) return null;

  const { description, fullName, image, name, path, price, score } = product;

  return (
    <div className={styles.wrapper} {...storyblokEditable(blok)} style={{ display: 'flex', flexDirection: 'column' }}>
      <button
        type="button"
        style={{
          fontSize: '.9rem',
          background: '#40c6c4',
          color: 'white',
          textAlign: 'left',
          padding: 2,
          paddingLeft: 4,
          maxHeight: '1.5rem',
          display: 'flex',
        }}
      >
        <SvgIcon icon="grip-dots-regular" /> {slug}
      </button>
      {!path && <div>WARNING! THIS PRODUCT DOES NOT HAVE A PATH</div>}
      <HtLink href={normalizeUrl({ url: path, origin: `nav-expanded/product:${blok.form_factor}` })}>
        <section className={styles.product}>
          <figure>
            {image ? (
              <>
                <ImageWrapper image={image} imgProps={{ alt: name || fullName, loading: 'eager' }} origin="nav-expanded-search" />
                {score && !isMediumOrMobileView ? (
                  <CircleScore progress={(score / HEARING_AID_MODEL_SCORE_TOTAL) * 100} amount={score} isRight isSmall origin="nav-expanded/product" />
                ) : null}
              </>
            ) : (
              <div className={styles.placeholder} />
            )}
          </figure>
          <section className={styles.details}>
            <h4>{fullName}</h4>
            <div className={styles.description}>
              {description ? <span>{description}</span> : <p>Description Missing (from published n4-product-config || metaDescription)</p>}
            </div>
            <div className={styles.price}>
              <Currency
                price={price?.price}
                fallback="Price not available"
                prefix="From "
                suffix={/month/.test(price?.priceType) ? ' / pair / mth' : ' / pair'}
              />
            </div>
          </section>
        </section>
      </HtLink>
    </div>
  );
};

export default NavProduct;
