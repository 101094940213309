// top-level container for N4 pages
import { useEffect, useState } from 'react';

import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import { fetchApi } from 'lib/ht_api';
import logger from 'lib/logger';
import { cx } from 'lib/utils';

import styles from './container.module.scss';

/* eslint-disable-next-line no-unused-vars */
const log = logger({ category: 'n4/Container' });

/*
 * blok has the current component and its children
 * story has the entire "page"
 * props has anything we load from our API (or anywhere else)
 *   usually these are build-time, so fairly static data -> update: useApiData will retrieve this now so you don't need to get it from props
 *   for dynamic data, fetch it from the api in a useEffect
 *   props has a variant variable that is 'A' or 'B' if there are any A/B elements on the page
 *
 * StoryblokComponent will render the children bloks of this blok. Those are all mapped to React components in
 * the project.
 */
const Container = ({ blok, story, ...props }) => {
  const isNav = /components\/nav/.test(story.full_slug);
  const [content, setContent] = useState();
  log.debug('blok: %o', blok);
  log.debug('layout: %o', { fullWidth: props.fullWidth, hideFooter: props.hideFooter, hideBreadcrumbs: props.hideBreadcrumbs });

  useEffect(() => {
    if (isNav) {
      const fn = async () => {
        // This is a POST because we are sending the story to be updated with the dynamic stuff and it's too big for GET.
        const result = await fetchApi({ path: 'nav/nav', method: 'POST', variables: { story }, fallback: {}, origin: 'container/components/nav' });
        setContent(result.content);
      };
      fn();
    }
  }, [isNav, blok, story]);

  const blocks = isNav ? content?.blocks : blok.blocks;

  return (
    <section className={cx(styles['n4-container'], props.fullWidth && styles['n4-container--full-width'], blok.className)} {...storyblokEditable(blok)}>
      <section className="n4-container-inner flex flex-col justify-around">
        {blocks
          ? blocks.filter((b) => !b.hidden).map((b) => <StoryblokComponent blok={b} story={story} {...props} key={b._uid} {...storyblokEditable(b)} />)
          : null}
      </section>
      <div id="debug-info" style={{ display: 'none' }}>
        {JSON.stringify(props.debug)}
      </div>
    </section>
  );
};

export const NonStoryContainer = ({ children, ...props }) => (
  <section className={cx(styles['n4-container'], props.fullWidth && styles['n4-container--full-width'], props.className)}>
    <section className="n4-container-inner flex flex-col justify-around">{children}</section>
  </section>
);

export default Container;
