import React, { ReactNode } from 'react';

import { storyblokEditable, type SbBlokData } from '@storyblok/react';
import ReactMarkdown from 'react-markdown';

import SvgIcon from 'components/ui/svg_icon';
import { cx } from 'lib/utils';

// import styles from './pros_and_cons.module.scss';

interface MarkdownProps {
  children?: ReactNode;
}

interface ProsConsBlok extends SbBlokData {
  pros_title?: string;
  cons_title?: string;
  pros: string;
  cons: string;
  heading_size?: string;
  columns?: boolean;
  component: string;
}

interface ProsAndConsProps {
  blok: ProsConsBlok;
}

interface ReactMarkdownComponentsType {
  ul: (props: MarkdownProps) => JSX.Element;
  li: (props: MarkdownProps) => JSX.Element;
  strong: (props: MarkdownProps) => JSX.Element;
}

const boldify = (text: ReactNode | ReactNode[] | null | undefined, hasColumns?: boolean): ReactNode => {
  if (!text) {
    return '';
  }
  // <strong>foo</strong: bar -> show as two lines
  if (text instanceof Array) {
    if (typeof text[1] === 'string' && text[1][0] === ':') {
      return (
        <dl className="mt-[5px] md:mt-[3px]">
          <dt className="-mt-2 font-medium">{text[0]}</dt>
          <dd className={cx(!hasColumns ? 'leading-[1.6]' : 'leading-[1.6]', 'font-normal  md:font-light md:leading-[inherit] ')}>{text[1].slice(2)}</dd>
        </dl>
      );
    }
    // any other array, just return what they sent
    return text;
  }
  if (typeof text === 'string') {
    const split = text.split(/--/).map((t) => t.trim());
    // if no double dash, return the text as normal
    if (split.length === 1) {
      return (
        <dl className="mt-[5px] md:mt-[3px]">
          <dd className={cx(!hasColumns ? '' : '', 'font-normal leading-[1.6] md:font-light md:leading-[inherit]  ')}>{split[0]}</dd>
        </dl>
      );
    }
    // if double dash, split onto two lines and return the first part bold, second part normal
    return (
      <dl className="mt-[5px] md:mt-[3px]">
        <dt className="-mt-2 font-medium">{split[0]}</dt>
        <dd className={cx(!hasColumns ? 'leading-[1.6]' : '', 'font-normal   ')}>{split[1]}</dd>
      </dl>
    );
  }
  return text;
};

const proComponents = (hasColumns?: boolean): ReactMarkdownComponentsType => ({
  ul: (props: MarkdownProps) => <ul className="m-0 flex flex-col items-start justify-start p-0">{props.children}</ul>,
  li: (props: MarkdownProps) => (
    <li className={cx(!hasColumns ? 'last:mb-0' : 'last:mb-0', 'm-0 mb-[1.88rem] flex items-start justify-start gap-4 self-stretch p-0 ')}>
      <div
        className={cx(
          'relative flex min-h-[2.125rem] min-w-[2.125rem] items-center justify-center',
          ' rounded-[2.5rem] border border-success md:min-h-[2.188rem] md:min-w-[2.188rem]'
        )}
      >
        <SvgIcon
          icon="check-solid"
          className="absolute inset-0 left-0.5 m-auto [filter:invert(31%)_sepia(62%)_saturate(2596%)_hue-rotate(117deg)_brightness(101%)_contrast(82%)]"
        />
      </div>
      <section className="flex flex-col gap-[0.5rem] self-center md:gap-[normal]">
        <span className={cx('font-hankenGrotesk text-[1.25rem] font-normal  leading-[1.7rem] tracking-[-0.0125rem] text-navy lg:font-light ')}>
          {boldify(props.children, hasColumns)}
        </span>
      </section>
    </li>
  ),
  strong: (props: MarkdownProps) => (
    <strong
      className={cx(
        'mt-[5px]  self-stretch text-[1.225rem] font-[650] leading-[1.6875rem] tracking-[-0.01125rem]',
        ' md:mt-0 md:text-[1.25rem] md:font-[550] md:leading-[2.1rem] md:tracking-[-0.0125rem]'
      )}
    >
      {props.children}
    </strong>
  ),
});

const errorComponents = (hasColumns?: boolean): ReactMarkdownComponentsType => ({
  ul: (props: MarkdownProps) => <ul className="m-0 flex flex-col items-start justify-start p-0">{props.children}</ul>,
  li: (props: MarkdownProps) => (
    <li className={cx('m-0 mb-[1.88rem] flex items-start justify-start gap-4 self-stretch p-0 last:mb-0')}>
      <div
        className={cx(
          'relative flex min-h-[2.125rem] min-w-[2.125rem]',
          ' items-center justify-center rounded-[2.5rem] border border-error md:min-h-[2.188rem] md:min-w-[2.188rem]'
        )}
      >
        <SvgIcon
          icon="xmark-solid"
          className="absolute inset-0 left-[0.313rem] m-auto [filter:invert(22%)_sepia(99%)_saturate(2361%)_hue-rotate(350deg)_brightness(96%)_contrast(92%)]"
        />
      </div>
      <section className="flex flex-col gap-[0.5rem] self-center md:gap-[normal]">
        <span className="font-hankenGrotesk text-[1.25rem] leading-[1.7rem] tracking-[-0.0125rem] text-navy md:font-normal lg:font-light">
          {boldify(props.children, hasColumns)}
        </span>
      </section>
    </li>
  ),
  strong: (props: MarkdownProps) => (
    <strong
      className={cx(
        'mt-[5px] self-stretch text-[1.225rem] font-[650] tracking-[-0.01125rem]',
        '  leading-[1.6875rem] md:mt-0 md:text-[1.25rem] md:font-[550] md:leading-[2.1rem] md:tracking-[-0.0125rem]'
      )}
    >
      {props.children}
    </strong>
  ),
});

interface TitleProps {
  title: string;
  heading?: string;
  hasColumns?: boolean;
}

const Title: React.FC<TitleProps> = ({ title, heading = 'h4', hasColumns }) => {
  const Tag = heading as keyof JSX.IntrinsicElements;

  const baseStyles = 'font-hankenGrotesk  text-navy';

  const styleMap = {
    'h4-columns': cx(
      ' !font-[550] !leading-[1.8rem] !tracking-[-0.015rem]  md:!leading-[2.1rem]',
      'md:!font-medium md:!tracking-[-0.0175rem] !mb-0 md:!text-[1.75rem] !text-[1.5rem]'
    ),

    'h4-no-columns': cx(
      '!text-[1.5rem] !font-[550] !leading-[1.8rem] md:!font-[500] md:!tracking-[-0.0175rem]',
      ' md:!text-[1.75rem] md:!leading-[2.1rem] !tracking-[-0.015rem] !mb-0'
    ),
  };

  const key = `${heading}${hasColumns ? '-columns' : '-no-columns'}`;

  const variantStyles = styleMap[key as keyof typeof styleMap];

  return <Tag className={cx(baseStyles, variantStyles)}>{title}</Tag>;
};

const ProsAndCons: React.FC<ProsAndConsProps> = ({ blok }) => (
  <section
    className={`my-12 flex min-w-full max-w-full flex-shrink-0 items-start justify-center md:min-w-full lg:min-w-[auto]  ${
      !blok?.columns ? 'my-12 w-full flex-col  xs:p-0 md:max-w-full md:p-0 lg:max-w-[640px]' : 'max-w-full lg:max-w-[810px] '
    } ${blok.component}`}
    {...storyblokEditable(blok)}
  >
    <div className={`flex w-full self-start  ${!blok?.columns ? ' flex  gap-[3rem] ' : 'gap-12 md:flex-row md:gap-[1.5rem]'} w-full flex-col  xs:p-0 `}>
      <section className="flex min-w-[24.75rem] flex-[1_0_0px] flex-col items-start gap-[1.25rem] xs:gap-5 md:min-w-[auto] md:gap-[1.875rem]">
        <Title title={blok.pros_title ? blok.pros_title : 'Positives'} heading={blok.heading_size || 'h4'} hasColumns={!!blok?.columns} />
        <ReactMarkdown className="n4-markdown-blok" components={proComponents(!!blok?.columns)}>
          {blok.pros}
        </ReactMarkdown>
      </section>
      <section className="flex min-w-[24.75rem] flex-[1_0_0px] flex-col items-start gap-[1.25rem] md:min-w-[auto]  md:gap-[1.875rem]">
        <Title title={blok.cons_title ? blok.cons_title : 'Negatives'} heading={blok.heading_size || 'h4'} hasColumns={!!blok?.columns} />
        <ReactMarkdown className="n4-markdown-blok" components={errorComponents(!!blok?.columns)}>
          {blok.cons}
        </ReactMarkdown>
      </section>
    </div>
  </section>
);

export default ProsAndCons;
