import React from 'react';

interface FeatureFlagsContextType {
  featureFlags: Record<string, string>;
  setFeatureFlags: React.Dispatch<React.SetStateAction<Record<string, string>>>;
}

const FeatureFlagsContext = React.createContext<FeatureFlagsContextType>({ featureFlags: {}, setFeatureFlags: () => null });

const useFeatureFlags = () => React.useContext(FeatureFlagsContext);

const FeatureFlagsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [featureFlags, setFeatureFlags] = React.useState<Record<string, string>>({});

  const value = React.useMemo<FeatureFlagsContextType>(() => ({ featureFlags, setFeatureFlags }), [featureFlags]);
  return <FeatureFlagsContext.Provider value={value}>{children}</FeatureFlagsContext.Provider>;
};

export { useFeatureFlags, FeatureFlagsProvider };
