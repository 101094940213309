import * as React from 'react';

import { storyblokEditable } from '@storyblok/react';
import NextLink from 'next/link';

import ImageWrapper from 'components/common-n4/image';
import SvgIcon from 'components/ui/svg_icon';
import { useIsMediumOrMobileView } from 'hooks/use_is_mobile_view';
import logger from 'lib/logger';
import { formatDate, normalizeUrl } from 'lib/utils';

import styles from '../nav/article.module.scss';
/* eslint-disable-next-line no-unused-vars */
const log = logger({ category: 'nav/Article' });

/* eslint-disable-next-line no-unused-vars */
const NavArticle = ({ blok, ...props }) => {
  log.debug('blok: %o', blok);
  const article = blok;
  const { large } = article;
  const [hasImageLoaded, setHasImageLoaded] = React.useState(false);
  const isMediumOrLessViewport = useIsMediumOrMobileView();
  const [titleOverride, imageOverride] = [article.title_override, article.image_override];
  const [date, setDate] = React.useState();
  const image = imageOverride && imageOverride.id ? imageOverride : normalizeUrl({ url: article.imageUrl, origin: `nav-expanded@#article:${article.slug}` });

  React.useEffect(() => {
    setDate(formatDate(article.republishedAt || article.publishedAt));
  }, [article.republishedAt, article.publishedAt]);

  log.debug('article: %o', article);

  return (
    <div className={styles.wrapper} {...storyblokEditable(blok)} style={{ display: 'flex', flexDirection: 'column' }}>
      <button
        type="button"
        style={{
          fontSize: '.9rem',
          background: '#40c6c4',
          color: 'white',
          textAlign: 'left',
          padding: 2,
          paddingLeft: 4,
          maxHeight: '1.5rem',
          display: 'flex',
        }}
      >
        <SvgIcon icon="grip-dots-regular" /> {article.slug}
      </button>
      <NextLink href={normalizeUrl({ url: article.slug, origin: `nav-expanded/article:${blok.slug}` })} rel={article.noindex ? 'nofollow' : undefined}>
        {!isMediumOrLessViewport && large ? (
          <section className={styles['article--row']}>
            <figure>
              {image ? (
                <ImageWrapper
                  image={image}
                  imgProps={{ alt: article.title, loading: 'eager' }}
                  onLoaded={() => setHasImageLoaded(true)}
                  origin="nav-expanded-article-lg"
                />
              ) : null}
              {!image || (image && !hasImageLoaded) ? <div className={styles.placeholder} /> : null}
              <div className={styles.icon}>
                <SvgIcon icon="arrow-right-regular" />
              </div>
            </figure>
            <section className={styles.details}>
              <h4>{titleOverride || article.title}</h4>
              {article.metaDescription ? <p>{article.metaDescription}</p> : <p>Description</p>}
            </section>
          </section>
        ) : (
          <section className={styles['article--column']}>
            <figure>
              {image ? (
                <ImageWrapper
                  image={image}
                  imgProps={{ alt: article.title, loading: 'eager' }}
                  onLoaded={() => setHasImageLoaded(true)}
                  origin="nav-expanded-article-md"
                />
              ) : null}
              {!image || (image && !hasImageLoaded) ? <div className={styles.placeholder} /> : null}
              <div className={styles.icon}>
                <SvgIcon icon="arrow-right-regular" />
              </div>
            </figure>
            <section className={styles.details}>
              <h4>{titleOverride || article.title}</h4>
              {article.metaDescription ? <p className={styles.description}>{article.metaDescription}</p> : <p>Description</p>}
              {date ? <div className={styles.date}>{date}</div> : null}
            </section>
          </section>
        )}
      </NextLink>
    </div>
  );
};

export default NavArticle;
