// Log a bunch of things together
import util from 'util';

import { htRandom } from 'lib/ts-utils';

import logger from './logger';

const log = logger({ category: 'trace' });

const level = 'debug';

interface MessageProps {
  message: string;
  optionalParams: unknown;
}
export interface TracerType {
  trace: (message: string, ...optionalParams: unknown[]) => number;
  dump: () => string;
  log: (group: string) => void;
  raw: MessageProps[];
}

const tracer = (): TracerType => {
  const traces: MessageProps[] = [];
  return {
    raw: traces,
    trace: (message: string, ...optionalParams: unknown[]) => traces.push({ message, optionalParams }),
    dump: () => {
      return traces
        .map((t) => {
          if (t instanceof String) {
            return t;
          }
          const { message, optionalParams } = t;
          return util.format(message, optionalParams);
        })
        .join('\n');
    },
    log: (group) => {
      if (!log.isLogging(level)) {
        return;
      }
      const g = `${group}-${Math.floor(htRandom() * 1000)}`;
      if (typeof window === 'undefined') {
        const args: unknown[] = [g];
        const out = ['----- start of %s -----'];
        traces.forEach((t, idx) => {
          const { message, optionalParams } = t;
          out.push(`${g}-${idx}: ${message}`);
          if (optionalParams) {
            args.push(...(optionalParams as unknown[]));
          }
        });
        out.push('----- end of %s -----');
        args.push(g);
        log[level](out.join('\n'), ...args);
      } else {
        // eslint-disable-next-line no-console
        console.group(g);
        log[level]('traces: %d', traces.length);
        traces.forEach((t, idx) => {
          const { message, optionalParams } = t;
          if (optionalParams) {
            log[level](`${idx}: ${message}`, ...(optionalParams as unknown[]));
          } else {
            log[level](`${idx}: ${message}`);
          }
        });
        log[level](`end of trace ${g}`);
        // eslint-disable-next-line no-console
        console.groupEnd();
      }
    },
  };
};

export default tracer;
