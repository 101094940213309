import React, { Dispatch } from 'react';

import type { SbBlokData } from '@storyblok/react';

import { getAb, recordAbEvent } from 'lib/ab-tests';
import { fetchApi } from 'lib/ht_api';
import logger from 'lib/logger';

import { questions } from './questions';
import { Step } from './step';
import { type Action, type State, Event, StateUpdate, SystemAction, UserAction } from './types';
import { findMinMaxDiff, matchesConditions } from './utils';

const log = logger({ category: 'wizard' });

const recordLocalEvent = (event: Event, state: State) => {
  recordAbEvent({
    event,
    segment: state.segment,
    testName: 'wizard',
    position: 'wizard',
    origin: state.segmentOrigin,
    cta: 'wizard',
    text: 'wizard',
    properties: {
      position: state.position,
      category: 'wizard',
      step: questions[state.step]?.id,
      priceRange: `${state.userPriceRange?.min}-${state.userPriceRange?.max}`,
      answers: JSON.stringify(state.answers),
    },
  });
};

const reducer = (state: State, action: Action): State => {
  const { type, payload } = action;
  log.info('reducer: %o', { state, action });
  // allow payload to set the error, otherwise clear whenever state changes
  const newState = { ...state, error: undefined, ...payload, fetching: false };
  switch (type) {
    case SystemAction.Error: {
      if (payload?.error) {
        log.remote('SystemAction.error: %s (1000)', JSON.stringify(payload).slice(0, 1000));
        recordLocalEvent(Event.Error, state);
      }
      // nothing to do, payload should contain the error
      break;
    }

    case SystemAction.Fetching: {
      newState.fetching = true;
      break;
    }

    case SystemAction.Initialize: {
      // nothing to do, payload should contain the segment
      const { min, max } = findMinMaxDiff(newState.products, 'initialize');
      newState.userPriceRange = { min, max };
      break;
    }

    case UserAction.Next: {
      newState.step = Math.min(newState.questions.length - 1, newState.step + 1);
      const { answers, questions: q } = newState;
      while (
        !matchesConditions({ question: q[newState.step].id, answers, conditions: q[newState.step].conditions }) &&
        newState.step < newState.questions.length - 1
      ) {
        newState.step += 1;
      }
      recordLocalEvent(Event.Next, newState);
      // TODO: handle last step
      break;
    }

    case UserAction.Back: {
      newState.step = Math.max(0, newState.step - 1);
      while (newState.step > 0) {
        const question = newState.questions[newState.step];
        if (!question.conditions) {
          break;
        }
        if (matchesConditions({ question: question.id, answers: newState.answers, conditions: question.conditions })) {
          break;
        }
        newState.step -= 1;
      }

      const answers = { ...newState.answers };
      for (let i = newState.step + 1; i < newState.questions.length; ++i) {
        const question = newState.questions[i];
        delete answers[question.id];
      }
      newState.answers = answers;

      recordLocalEvent(Event.Back, newState);
      // TODO: handle first step
      break;
    }

    case UserAction.Restart: {
      newState.answers = {};
      newState.step = 0;
      recordLocalEvent(Event.Restart, newState);
      break;
    }

    case UserAction.PriceRange: {
      const { userPriceRange } = payload as StateUpdate;
      newState.userPriceRange = userPriceRange!;
      // newState.priceRange = priceRange;
      recordLocalEvent(Event.PriceRange, newState);
      break;
    }

    case UserAction.Answer: {
      const { answer } = payload as StateUpdate;
      const question = newState.questions[state.step];
      newState.answers = { ...newState.answers, [question.id]: answer || '' };
      recordLocalEvent(Event.Answer, newState);
      break;
    }
  }
  log.debug('state: %o, payload: %o', newState, payload);
  return newState;
};

interface Blok extends SbBlokData {
  position?: string;
}

interface StoryContent {
  blocks: SbBlokData[];
}

interface Story {
  content: StoryContent;
  cv: number;
  slug: string;
}

interface WizardProps {
  blok: Blok;
  story: Story;
}

const defaultState = {
  step: 0,
  questions,
  answers: {},
  segment: -2,
  products: [],
  fetching: true,
  userPriceRange: { min: 0, max: 99999 },
};

export const WizardContext = React.createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({
  state: { ...defaultState, position: '', segmentOrigin: '' },
  dispatch: () => {},
});

const Wizard: React.FC<WizardProps> = ({ blok, story }) => {
  const segmentOrigin = story.slug;
  const position = blok.position ? `wizard-${blok.position}` : 'wizard-article';
  const [state, dispatch] = React.useReducer(reducer, { ...defaultState, position, segmentOrigin } as State);

  React.useEffect(() => {
    const fn = async () => {
      const { segment } = getAb('wizard');
      const products = await fetchApi({ path: 'directory/wizard', fallback: [], origin: 'wizard', log });
      dispatch({ type: SystemAction.Initialize, payload: { segment, products } });
    };
    fn();
  }, []);

  /*
  React.useEffect(() => {
    const fn = async () => {
      dispatch({ type: SystemAction.Fetching });
      if (state.products) {
        const matches = await findMatches({
          answers: state.answers,
          // priceRange: state.priceRange,
          products: state.products,
          log,
          allowDups: true,
        });
        // TODO: error handling
        dispatch({ type: SystemAction.SetMatches, payload: { matches } });
      }
    };
    fn();
  }, [state.answers, state.products]);
  */

  return (
    <WizardContext.Provider value={{ state, dispatch }}>
      <section className="mx-auto my-8 max-w-full lg:w-[810px] lg:max-w-[810px]">
        <div className="mb-5 text-2xl font-medium tracking-tight text-navy">
          Hearing Aid Explorer
          <span
            className="text-navy-blue relative ml-2 inline-block -translate-y-[.1rem] rounded-[3.4375rem] bg-[#baceed] 
          px-[1rem] py-[0.5rem] text-[1rem] font-normal leading-[1.2rem] tracking-[-0.01rem]"
          >
            Beta
          </span>
        </div>
        <Step />
      </section>
    </WizardContext.Provider>
  );
};

export default Wizard;
