import React from 'react';

import { type SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { usePostHog } from 'posthog-js/react';

import { useFeatureFlags } from 'hooks';
import { track } from 'lib/tracking';
import { isLocalStorageAvailable } from 'lib/ts-utils';
import { FeatureFlagStoryblok } from 'types/storyblok-component-types';

interface StoryContent {
  blocks: SbBlokData[];
}

interface Story {
  content: StoryContent;
  cv: number;
}

interface FeatureFlagProps {
  blok: FeatureFlagStoryblok;
  story: Story;
  version: string;
}

const FeatureFlag: React.FC<FeatureFlagProps> = ({ blok, story, version, ...props }) => {
  const { setFeatureFlags } = useFeatureFlags();
  const client = usePostHog();
  const [on, setOn] = React.useState(false);

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('features-off') !== null) {
      setOn(false);
      return;
    }
    if (params.get('features-on') !== null) {
      setOn(true);
      return;
    }
    if (version === 'draft' && blok.force_on) {
      setOn(true);
      return;
    }
    const phVariableOn = `${blok.feature}-present`;
    const phVariableOff = `${blok.feature}-absent`;
    client.unregister(phVariableOn);
    client.unregister(phVariableOff);
    client.onFeatureFlags(() => {
      if (client.isFeatureEnabled(blok.feature)) {
        setOn(true);
        client.register({ [phVariableOn]: window.location.pathname });
        track({ event: 'feature-view', eventType: 'feature-view', origin: window.location.pathname, properties: { [blok.feature]: true } });
        if (isLocalStorageAvailable()) {
          localStorage.setItem('utm-feature', phVariableOn);
        }
      } else {
        track({ event: 'feature-view', eventType: 'feature-view', origin: window.location.pathname, properties: { [blok.feature]: false } });
        setOn(false);
        client.register({ [phVariableOff]: window.location.pathname });
        if (typeof localStorage !== 'undefined') {
          localStorage.removeItem('utm-feature');
        }
      }
    });
    return () => {
      client.unregister(phVariableOn);
      client.unregister(phVariableOff);
      if (isLocalStorageAvailable()) {
        localStorage.removeItem('utm-feature');
      }
    };
  }, [blok, version, client]);

  React.useEffect(() => {
    setFeatureFlags((prev) => ({ ...prev, [blok.feature]: on ? 'on' : 'off' }));
  }, [setFeatureFlags, blok.feature, on]);

  if (on) {
    return (blok.blocks || []).map((b) => <StoryblokComponent blok={b} key={b._uid} story={story} {...props} {...storyblokEditable(b)} />);
  }
  return null;
};

export default FeatureFlag;
