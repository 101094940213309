import React from 'react';

import { ClassNameValue } from 'tailwind-merge';

import { HEARING_AID_MODEL_SCORE_TOTAL } from 'components/common/constants';
import { Skeleton } from 'components/common/skeleton/skeleton-ts';
import { CircleScoreFluid } from 'components/common-n4/circle-score-fluid';
import HtLink from 'components/common-n4/ht-link';
import ImageWrapper from 'components/common-n4/image';
import Price from 'components/widgets/price';
import { normalizeUrl, tw } from 'lib/utils';
import type { NavProduct } from 'types/release';

import { Panel } from './panel';

interface ProductProps {
  product: NavProduct;
  className?: ClassNameValue;
}

const origin = 'article/featured-products';

const ProductSkeleton: React.FC<ProductProps> = ({ className }) => {
  return (
    <div className={tw('flex gap-5', className)}>
      <div className="w-[50%]">
        <Skeleton height="95px" />
      </div>
      <div className="flex w-[50%] flex-col gap-y-2">
        <div className="flex">
          <Skeleton height="30px" />
        </div>
        <div className="flex">
          <Skeleton height="50px" />
        </div>
      </div>
    </div>
  );
};

const Product: React.FC<ProductProps> = ({ product, className }) => {
  return (
    <div className={tw('flex gap-5', className)}>
      {product.image && (
        <div className="relative shrink-0">
          <HtLink
            href={normalizeUrl({ url: product.path, origin })}
            data-track
            data-track-impression
            data-event-name="sidebar-product"
            data-event-release={product.releaseSlug}
            data-origin={origin}
          >
            <ImageWrapper
              key={product.name}
              image={product.openGraphImage || product.socialImage}
              origin={origin}
              imgProps={{ alt: product.fullName, className: 'bg-white rounded-[10px] w-[145px] h-[95px] object-cover border border-navy-10' }}
            />
            {product.score && (
              <CircleScoreFluid
                size="xs"
                amount={product.score}
                progress={(product.score / HEARING_AID_MODEL_SCORE_TOTAL) * 100}
                className="absolute left-2 top-2 shadow-[1px_2px_2px_0_rgba(0,0,0,0.15)]"
                origin={origin}
              />
            )}
          </HtLink>
        </div>
      )}
      <div className="flex flex-col gap-1">
        <div className="line-clamp-1 text-lg leading-[140%] tracking-tight text-navy">
          <HtLink
            href={normalizeUrl({ url: product.path, origin })}
            data-track
            data-track-impression
            data-event-name="sidebar-product"
            data-event-release={product.releaseSlug}
            data-origin={origin}
          >
            {product.releaseName}
          </HtLink>
        </div>
        <div className="line-clamp-2 text-sm font-normal not-italic leading-[1.225rem] tracking-[-0.02625rem] text-[#727c9d]">{product.description}</div>
        <div className="text-sm leading-5 tracking-tight text-lapis">
          <Price prefix="From " loading="Loading price..." releaseSlug={product.slug} price={product.price} noGeo origin={`cms/${origin}`} />
        </div>
      </div>
    </div>
  );
};

interface FeaturedProductsProps {
  products: NavProduct[];
  skeleton: boolean;
}

export const FeaturedProducts: React.FC<FeaturedProductsProps> = ({ products, skeleton = false }) => {
  return (
    <Panel className="flex flex-col gap-6">
      <ul className="flex gap-2">
        <li>
          <span className="text-xl leading-7 tracking-tight text-navy">Featured Products</span>
        </li>
      </ul>
      <div className="flex flex-col gap-4">
        {products?.map((product, index) =>
          skeleton ? (
            <ProductSkeleton key={index} product={product} className={index === products.length - 1 && 'border-none pb-0'} />
          ) : (
            <Product key={index} product={product} className={index === products.length - 1 && 'border-none pb-0'} />
          )
        )}
      </div>
    </Panel>
  );
};
