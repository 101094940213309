import React from 'react';

import { SoundPerformanceLabResults } from 'components/common-n4/product/sound-performance-lab-results';
import type { HearingAid } from 'types/release';
import { AssetStoryblok } from 'types/storyblok-component-types';

interface LabRecordingsTabProps {
  hearingAid: HearingAid;
  labResultImages?: AssetStoryblok[];
}

export const LabRecordingsTab: React.FC<LabRecordingsTabProps> = ({ hearingAid, labResultImages }) => {
  return (
    <div className="py-[32px]">
      <SoundPerformanceLabResults hearingAid={hearingAid} labResultImages={labResultImages || []} />
    </div>
  );
};
