/* eslint-disable no-console */
// This error boundary is above all the other providers so doesn't have access to layout, session, etc
import React from 'react';

import ph from 'posthog-js';

import { logRemotelyClient } from 'lib/logger';
import { isDevelopment } from 'lib/ts-utils';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidMount() {
    window.addEventListener('error', (e) => {
      try {
        const flags = ph.featureFlags.getFlagPayloads();
        const { error, filename, lineno, message, type } = e;
        const { stack } = error || {};
        // TODO: should we show anything to the user?
        logRemotelyClient('%o', { source: 'onerror', message, error, filename, lineno, type, stack, flags });
      } catch (ex) {
        console.error('%o', ex);
      }
    });
  }

  componentDidCatch(error, errorInfo) {
    const flags = ph.featureFlags.getFlagPayloads();
    const stack = error?.stack;
    logRemotelyClient('%o', { source: 'global-error-boundary', error, stack, errorInfo, flags })
      .then(() => console.log('done logging'))
      .catch((ex) => console.error(ex));
  }

  render() {
    // Check if the error is thrown
    const { hasError, error } = this.state;
    const { children } = this.props;
    if (hasError && !isDevelopment) {
      return (
        <section className="ml-auto mr-auto flex flex-col items-center justify-center pb-[3rem] pt-[3rem]">
          <section className="w-[60%]">
            <h2 className="text-lg">Something went wrong</h2>
            <p className="mt-[1rem]">
              We are aware and are looking into it. Please try again or reload the page to see if that helps. If you continue to have problems send an e-mail to
              info@hearingtracker.com with as much information as possible. Thanks!
            </p>
            <div className="hidden">{error.message}</div>
          </section>
        </section>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
