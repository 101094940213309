import React from 'react';

import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import logger from 'lib/logger';
import { track } from 'lib/tracking';
import { cx } from 'lib/utils';

import styles from './panel_tab.module.scss';

const log = logger({ category: 'nav/PanelTab' });

/* eslint-disable-next-line no-unused-vars */
const PanelTab = ({ blok, story, id, ...props }) => {
  log.debug('blok: %o', blok);

  React.useEffect(() => {
    if (blok.title === 'Featured') {
      blok.items.forEach((item) => {
        if (item.component === 'nav-product') {
          const properties = {
            release: item.slug,
          };
          track({ event: `nav-product--impression`, eventType: 'impression', origin: window.location.pathname, properties });
        }
      });
    }
  }, [blok]);

  const { items } = blok;
  const isProducts = blok.items?.[0].component === 'nav-product';
  const isLarge = blok.large;

  const expand =
    story?.full_slug?.endsWith('components/nav') &&
    !/no-expand/.test(story.content.debug) &&
    (typeof window === 'undefined' || !/no-expand/.test(window.location.search));

  return (
    <section
      key={id}
      className={cx(styles['panel-tab'], 'animate-fadeIn', isLarge && styles['panel-tab--large'], isProducts && styles['panel-tab--products'])}
      style={expand ? { maxHeight: 2000 } : {}}
      {...storyblokEditable(blok)}
    >
      {items.map((item) => (
        <StoryblokComponent blok={item} story={story} {...props} key={item._uid} {...storyblokEditable(item)} />
      ))}
    </section>
  );
};

export default PanelTab;
