import React from 'react';

import { type SbBlokData, storyblokEditable } from '@storyblok/react';
import ReactMarkdown from 'react-markdown';

interface BlockquoteBlok extends SbBlokData {
  id: string;
  quote: string;
  author?: string;
}

interface BlockquoteProps {
  blok: BlockquoteBlok;
}

const Blockquote: React.FC<BlockquoteProps> = ({ blok }) => (
  <section {...storyblokEditable(blok)} className="my-16 ml-12 flex flex-col lg:ml-0" id={`blockquote-${blok.id}`}>
    <blockquote
      className="
        relative max-w-[640px]
        before:absolute before:left-[-3rem]
        before:h-8 before:w-8 before:opacity-10 before:content-blockquote after:absolute
        after:bottom-0 after:right-[-3rem] after:hidden after:h-12 after:w-12 after:rotate-180 after:opacity-10
        after:content-blockquote lg:before:left-[-6rem] lg:before:h-12 lg:before:w-12 lg:after:right-[-5rem] lg:after:inline lg:after:h-12 lg:after:w-12
      "
    >
      <ReactMarkdown className="font-normal leading-[2]">{blok.quote}</ReactMarkdown>
    </blockquote>
    {blok.author && (
      <cite
        className="
          relative ml-[3px] max-w-[640px] pl-[24px] pt-6 text-left indent-0
          text-[1rem] font-[200] not-italic before:absolute
          before:left-0 before:top-[1.5rem] before:content-['—'] lg:ml-0 lg:pl-5
        "
      >
        {blok.author}
      </cite>
    )}
  </section>
);

export default Blockquote;
