/* eslint-disable max-len */
import React from 'react';

import NextLink from 'next/link';
import { ClassNameValue } from 'tailwind-merge';

import { Skeleton } from 'components/common/skeleton/skeleton-ts';
import ImageWrapper from 'components/common-n4/image';
import { formatDate, normalizeUrl, tw } from 'lib/utils';
import type { NavArticle } from 'types/release';

import { Panel } from './panel';

interface ArticleProps {
  article: NavArticle;
  className?: ClassNameValue;
}

const origin = 'article/featured-articles';

const ArticleSkeleton: React.FC<ArticleProps> = ({ className }) => {
  return (
    <div className={tw('flex gap-5', className)}>
      <div className="w-[50%]">
        <Skeleton height="95px" className="rounded-[10px]" />
      </div>
      <div className="flex w-[50%] flex-col gap-y-2">
        <div className="flex">
          <Skeleton height="30px" />
        </div>
        <div className="flex">
          <Skeleton height="50px" />
        </div>
      </div>
    </div>
  );
};

const Article: React.FC<ArticleProps> = ({ article, className }) => {
  const [publishedDate, setPublishedDate] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (article.republishedAt || article.publishedAt) {
      setPublishedDate(formatDate(article.republishedAt || article.publishedAt));
    }
  }, [article.republishedAt, article.publishedAt]);

  return (
    <div className={tw('flex gap-5 border-b border-navy-10 pb-5', className)}>
      {article.imageUrl && (
        <NextLink
          prefetch={false}
          href={normalizeUrl({ url: article.slug, origin })}
          className="shrink-0"
          data-crack
          data-event-name="sidebar-article-click"
          data-origin={origin}
        >
          <ImageWrapper
            key={article.title}
            image={article.imageUrl}
            origin={origin}
            imgProps={{ className: 'bg-white rounded-[10px] w-[128px] h-[96px] object-cover' }}
          />
        </NextLink>
      )}
      <div className="flex flex-col justify-between">
        <div className="line-clamp-3 text-base leading-[140%] tracking-tight text-navy">
          <NextLink prefetch={false} href={normalizeUrl({ url: article.slug, origin })} data-track data-event-name="sidebar-article">
            {article.title}
          </NextLink>
        </div>
        {publishedDate && <div className="text-sm leading-5 tracking-tight text-lapis">{publishedDate}</div>}
      </div>
    </div>
  );
};

interface FeaturedArticlesProps {
  articles: NavArticle[];
  skeleton: boolean;
}

export const FeaturedArticles: React.FC<FeaturedArticlesProps> = ({ articles, skeleton = false }) => {
  return (
    <Panel className="flex flex-col gap-6">
      <div className="text-xl leading-7 tracking-tight text-navy">Recent Articles</div>
      {articles?.map((article, index) =>
        skeleton ? (
          <ArticleSkeleton key={article.title} article={article} className={index === articles.length - 1 && 'border-none pb-0'} />
        ) : (
          <Article key={article.title} article={article} className={index === articles.length - 1 && 'border-none pb-0'} />
        )
      )}
      {!skeleton && (
        <NextLink prefetch={false} href="/news" className="leading-2 self-start text-xs tracking-tight text-lapis underline">
          View all
        </NextLink>
      )}
    </Panel>
  );
};
