import React, { useEffect, useRef, useState } from 'react';

import { Tab } from '@headlessui/react';
import * as Popover from '@radix-ui/react-popover';
import ScrollBooster from 'scrollbooster';

import { HEARING_AID_MODEL_SCORE_TOTAL } from 'components/common/constants.js';
import { CircleScoreFluid } from 'components/common-n4/circle-score-fluid';
import ImageWrapper from 'components/common-n4/image';
import { ViewMoreButton } from 'components/common-n4/view-more-button';
import fonts from 'components/layout/fonts';
import Price from 'components/widgets/price';
import { WINDOW_DIMENSION_TYPE, useWindowDimensions } from 'hooks/use_window_dimensions';
import IconInfo from 'images/icon-info.svg';
import logger from 'lib/logger';
import { cx, getWindowDimensionType, tw } from 'lib/utils';
import type { Model, HearingAid } from 'types/release';

import { SubScore } from './sub-scores';
import { useSubScore } from './use-sub-scores';

import { Tooltip, TooltipSubtitle } from '../../tooltip';

const log = logger({ category: 'n4/ExpertReview' });

interface ExpertReviewProps {
  hearingAids: HearingAid[];
  models: Model[];
}

interface HearingAidTabProps {
  hearingAid: HearingAid;
  models: Model[];
  selected: boolean;
}

interface HearingAidTabPanelProps {
  hearingAid: HearingAid;
}

const HearingAidTab: React.FC<HearingAidTabProps> = ({ hearingAid, models, selected }) => {
  const { modelId } = hearingAid;
  const model = models.find((m) => m.id === modelId) || models[0];
  const image = model.image;

  const releaseSlug = model.release_slug;

  return (
    <div
      className={tw(
        'flex items-center gap-4 rounded-[10px] px-[24px] pb-[8px] pt-[10px] shadow-[0_0_0_1px_#E7E9EC] lg:px-3 lg:py-[13px]',
        selected && 'shadow-[0_0_0_2px_#4A7FE5] lg:shadow-[0_0_0_3px_#4A7FE5]'
      )}
    >
      <ImageWrapper
        image={image}
        sizingProps={{ fixed: true, fixedWidth: 50, fixedHeight: 50 }}
        imgProps={{ alt: hearingAid.name, className: 'hidden lg:block' }}
        origin="expert-review"
      />
      <div className="text-left">
        <div className="lg:leading-[120%]">
          <span className="text-[20px] font-medium leading-[120%] -tracking-[0.6px] text-navy lg:text-xl">{hearingAid.name}</span>
        </div>
        <div className="lg:leading-[140%]">
          <span className="text-[16px] leading-[140%] -tracking-[0.48px] text-lapis lg:text-base">
            <Price
              prefix="From "
              releaseSlug={releaseSlug}
              hearingAidId={hearingAid.id}
              loading="Loading price..."
              noGeo={false}
              origin="product/expert-review"
            />
          </span>
        </div>
      </div>
    </div>
  );
};

const HearingAidTabPanel: React.FC<HearingAidTabPanelProps> = ({ hearingAid }) => {
  const { scores } = hearingAid;
  const [isViewMore, setIsViewMore] = useState<boolean>(false);

  const subScoresWithScores = useSubScore(scores);

  return (
    <section className="flex flex-col gap-[40px] lg:flex-row lg:gap-28">
      <div className="flex-[8_8_0%]">
        <div className="mb-3 flex items-center justify-between">
          <h3 className="text-[20px] font-medium -tracking-[0.6px] text-navy lg:text-xl">HearingTracker Score</h3>
          <Popover.Root>
            <Popover.Trigger asChild>
              <button type="button">
                <IconInfo />
              </button>
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content sideOffset={5} side="top">
                <Tooltip>
                  <TooltipSubtitle>The HearingTracker score is the weighted average of all components scores seen on this score card.</TooltipSubtitle>
                </Tooltip>
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        </div>
        <div className="flex flex-col items-center gap-9 rounded-[20px] border border-navy-20 px-8 py-9 lg:border-none lg:p-12 lg:pb-11 lg:shadow-sm">
          {scores?.total_score && (
            <div className="max-w-[167px] lg:max-w-[236px]">
              <CircleScoreFluid
                size="2xl"
                progress={((scores?.total_score || 0) / HEARING_AID_MODEL_SCORE_TOTAL) * 100}
                amount={scores?.total_score || 0}
                className="shadow-[0_4px_30px_0_rgba(0,0,0,0.15)]"
                origin="product/expert-review"
              />
            </div>
          )}
          <div className="leading-[140%]">
            {scores.tier && (
              <h5 className="text-base">
                {hearingAid.releaseFullName} is in the {scores.tier} of all products tested
              </h5>
            )}
            {/* <p className="mt-2 text-[20px] font-light leading-[140%] lg:text-xl">{scores?.summary}</p> */}
          </div>
        </div>
      </div>
      {subScoresWithScores.length > 0 && (
        <div className="flex-[9_9_0%]">
          <div className="mb-4 lg:mb-3">
            <h3 className="text-[20px] font-medium -tracking-[0.6px] text-navy lg:text-xl">Full hands-on review</h3>
          </div>
          <ul className="flex flex-col gap-4">
            {subScoresWithScores.map((subScore, index) => (
              <SubScore
                key={subScore?.subScoreKey}
                subScoreKey={subScore?.subScoreKey}
                subScore={subScore}
                className={!isViewMore && index > 3 && 'hidden lg:block'}
              />
            ))}
          </ul>
          <ViewMoreButton
            isViewMore={isViewMore}
            negativeStateText="View more scores"
            positiveStateText="View fewer scores"
            onViewMoreButtonClick={() => setIsViewMore((currentIsViewMore) => !currentIsViewMore)}
            className="mt-8 lg:hidden"
          />
        </div>
      )}
    </section>
  );
};

export const ExpertReview: React.FC<ExpertReviewProps> = ({ hearingAids, models }) => {
  log.debug('hearingAids: %o', hearingAids);
  const viewportRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLUListElement>(null);
  const { width } = useWindowDimensions();
  const dimensionType = getWindowDimensionType(width);

  useEffect(() => {
    let sb: ScrollBooster;
    if (viewportRef.current && contentRef.current && dimensionType !== WINDOW_DIMENSION_TYPE.md && dimensionType !== WINDOW_DIMENSION_TYPE.mobile) {
      sb = new ScrollBooster({
        viewport: viewportRef.current,
        content: contentRef.current,
        scrollMode: 'transform',
        direction: 'horizontal',
      });
    }

    return () => {
      sb?.destroy();
    };
  }, [dimensionType]);

  return (
    <Tab.Group
      as="section"
      className={cx(
        fonts.hankenGrotesk.className,
        'flex max-w-[1344px] flex-col gap-9 rounded-[20px] bg-white px-[24px] py-[32px] shadow-xl lg:gap-12 lg:p-12 lg:pb-16'
      )}
    >
      {hearingAids.length > 1 && (
        <div className="flex flex-col gap-4 border-b border-neutral-300 pb-7 lg:block lg:pb-8">
          <h2 className="text-xl -tracking-[0.54px] lg:hidden">Models</h2>
          <div className="overflow-auto lg:overflow-hidden" ref={viewportRef}>
            <Tab.List as="ul" className="flex flex-col gap-5 p-[2px] lg:flex-row lg:items-center lg:gap-5 lg:px-1 lg:py-1" ref={contentRef}>
              {hearingAids.map((hearingAid, index) => (
                <li key={hearingAid.name}>
                  <Tab className={tw('w-full outline-0 lg:w-max', index === hearingAids.length - 1 && 'pr-1')}>
                    {({ selected }) => <HearingAidTab hearingAid={hearingAid} models={models} selected={selected} />}
                  </Tab>
                </li>
              ))}
            </Tab.List>
          </div>
        </div>
      )}
      <Tab.Panels as="section">
        {hearingAids.map((hearingAid) => (
          <Tab.Panel key={hearingAid.name}>
            <HearingAidTabPanel hearingAid={hearingAid} />
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};
