import React from 'react';

import { ClassNameValue } from 'tailwind-merge';

import { HEARING_AID_MODEL_SCORE_TOTAL } from 'components/common/constants.js';
import { Button } from 'components/common-n4/button';
import { CircleScoreFluid } from 'components/common-n4/circle-score-fluid';
import fonts from 'components/layout/fonts';
import PriceButton from 'components/widgets/price-button';
import { useApiData } from 'hooks';
import { fetchApi } from 'lib/ht_api';
import logger from 'lib/logger';
import { scrollToAnchorWithOffset } from 'lib/ts-utils';
import { tw } from 'lib/utils';
import IconCircleCheck from 'styles/icons-component/sharp-solid/circle-check.svg';
import type { Release, Tag } from 'types/release';

import styles from './product-scorecard.module.scss';

import { ProductFeatures } from './product-features';

const log = logger({ category: 'n4/ProductScorecard' });

interface ProductScorecardProps {
  release: Release;
  className: ClassNameValue;
}

interface IdealScenariosProps {
  tags: string[];
  className?: ClassNameValue;
  titleClassName?: string;
  origin?: string;
}

export const IdealScenarios: React.FC<IdealScenariosProps> = ({ tags, className, origin = '' }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const inPopup = /popup/.test(origin);
  const { api } = useApiData();
  const [allTags, setAllTags] = React.useState(api.tags || []);

  React.useEffect(() => {
    const fn = async () => {
      if (api.tags?.length) {
        setAllTags(api.tags);
      } else {
        // Doing this for compare pages which don't have all the tags yet
        const fetchedTags = await fetchApi({
          path: 'settings/model_tags',
          fallback: [],
          origin: 'product/product-scorecard/ideal-scenarios',
        });
        setAllTags(fetchedTags);
      }
    };
    fn();
  }, [api.tags]);

  const includedTags = tags.filter((t) => allTags.some((tag: Tag) => tag.tag === t && !tag.configuration.exclude_on_product));
  if (!includedTags.length) {
    return null;
  }

  return (
    <section className={tw(!inPopup ? 'lg-short:hidden' : 'my-7', className)}>
      <div className={tw('text-xl font-[300] tracking-tight text-navy')}>Ideal for</div>
      <ul className="mt-5 flex flex-col gap-3">
        {includedTags.map((tag) => (
          <li key={tag} className="flex items-center gap-3">
            <IconCircleCheck width="24" height="24" fill="#179F2D" />
            <span className="text-[17px] leading-[140%] tracking-tight text-navy sm:text-md">{tag}</span>
          </li>
        ))}
      </ul>
    </section>
  );
};

export const ProductScorecard: React.FC<ProductScorecardProps> = ({ release, className }) => {
  const [multiPrice, setMultiPrice] = React.useState(false);
  log.debug('release: %o', release);

  React.useEffect(() => {
    setMultiPrice(Object.keys(release.prices?.sellers || {}).length > 1);
  }, [release.prices]);

  return (
    <section className={tw(styles['product-scorecard'], fonts.hankenGrotesk.className, className)}>
      {release.score && (
        <section className={styles.top}>
          {release.score && (
            <CircleScoreFluid
              size="lg"
              progress={(release.score / HEARING_AID_MODEL_SCORE_TOTAL) * 100}
              amount={release.score}
              className="h-[100px] shadow-[1px_2px_10px_0_rgba(0,0,0,0.15)]"
              origin="bloks/n4/product/product-scorecard"
            />
          )}
          <div className={styles['top-right']}>
            <div className={styles['top-right-label']}>HearingTracker Score</div>
            {release.tier && (
              <div className={styles['top-right-title']}>
                {release.full_name} is in the {release.tier} of all products tested
              </div>
            )}
            <div className={styles['top-right-link']}>
              <a className="underlined" href="#expert-review">
                View Scoring breakdown
              </a>
            </div>
          </div>
        </section>
      )}
      {release.tags?.length ? <IdealScenarios tags={release.tags} className="h750:border-b h750:border-gray h750:pb-7" /> : null}
      <ProductFeatures release={release} />
      {multiPrice && (
        <Button.LinkPrimary
          href="#"
          className="text-[16px] leading-[130%] -tracking-[0.48px] text-white lg:text-[17px]"
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            scrollToAnchorWithOffset('prices', -70);
            return false;
          }}
        >
          View Prices
        </Button.LinkPrimary>
      )}
      {!multiPrice && <PriceButton release={release} prices={release.prices} origin="product/scorecard" position="product-page-main-scorecard" />}
    </section>
  );
};
