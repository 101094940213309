import * as React from 'react';

import * as Popover from '@radix-ui/react-popover';

import fonts from 'components/layout/fonts';
import { callAll, cx } from 'lib/utils';

import styles from './popover.module.scss';

export * from '@radix-ui/react-popover';

const PopoverContext = React.createContext(null);

export const usePopoverContext = (ignoreErrors) => {
  const context = React.useContext(PopoverContext);

  if (!context) {
    if (ignoreErrors) {
      return {
        open: () => null,
        setOpen: () => null,
        behaviour: 'click',
        triggerRef: null,
        contentRef: null,
        close: () => null,
      };
    }
    throw new Error(`usePopoverContext() must be called inside <Popover.Root />`);
  }

  return context;
};

export const Portal = (props) => <Popover.Portal {...props} />;

export const Anchor = (props) => <Popover.Anchor {...props} />;

export const Root = ({ behaviour = 'click', ...props }) => {
  const [open, setOpen] = React.useState(false);
  const triggerRef = React.useRef(null);
  const contentRef = React.useRef(null);

  const isControlled = props.open !== undefined;

  const close = React.useCallback(() => {
    setOpen(false);
    props.onClose();
  }, [props]);

  const context = React.useMemo(() => {
    if (isControlled) {
      return {
        open: props.open,
        setOpen: props.onOpenChange,
        behaviour,
        triggerRef,
        contentRef,
        close: props.close,
      };
    }

    return { open, setOpen, behaviour, triggerRef, contentRef, close };
  }, [behaviour, close, isControlled, open, props.close, props.onOpenChange, props.open]);

  const rootProps = React.useMemo(() => {
    if (isControlled) {
      return props;
    }

    return {
      ...props,
      open,
      onOpenChange: (isOpen) => {
        // seems to allow closing nav on mobile when clicking on the background (whatever is behind popup),
        // but when clicking on the "x" to close the menu Triggers Warning:
        // Cannot update a component (`StoryblokNav`) while rendering a different component (`Root`).
        // To locate the bad setState() call inside `Root`, follow the stack trace as described in https://reactjs.org/link/setstate-in-render
        // Update: not seeing that warning; also needed to be able to do some state cleanup onClose so adding optional props.onClose
        if (!isOpen && props.onClose) {
          props.onClose();
        }
        setOpen(isOpen);
      },
    };
  }, [isControlled, open, props]);

  // console.log('root open: %s -> %o, rp: %o', props.name, props.open, rootProps);

  return (
    <PopoverContext.Provider value={context}>
      <Popover.Root {...rootProps} data-popover-root />
    </PopoverContext.Provider>
  );
};

const TriggerRoot = (props, ref) => {
  const { onMouseEnter, onMouseLeave } = props;
  const setOpen = usePopoverContext()?.setOpen;
  const behaviour = usePopoverContext()?.behaviour;
  const isKeyPress = React.useRef(false);
  const triggerRef = usePopoverContext()?.triggerRef;
  const contentRef = usePopoverContext()?.contentRef;

  React.useImperativeHandle(ref, () => triggerRef.current);

  const handleMouseLeave = (e) => {
    if (contentRef?.current && !contentRef.current.contains(e.target)) {
      setOpen?.(false);
    }
  };

  return (
    <Popover.Trigger
      {...props}
      ref={triggerRef}
      onClick={(e) => {
        // prevent the mouse click from closing the popover when
        // in the 'hover' mode. otherwise a user can click on an
        // a trigger whilst it is open, and it will close the modal
        if (behaviour === 'hover' && !isKeyPress.current) {
          e.preventDefault();
        }
        isKeyPress.current = false;
      }}
      data-popover-trigger
      onKeyDown={() => {
        isKeyPress.current = true;
      }}
      onMouseEnter={callAll(behaviour === 'hover' && (() => setOpen?.(true)), behaviour === 'hover' && (() => triggerRef?.current?.focus()), onMouseEnter)}
      onMouseLeave={callAll(behaviour === 'hover' && handleMouseLeave, onMouseLeave)}
    />
  );
};

export const Trigger = React.forwardRef(TriggerRoot);

const ContentRoot = ({ children, className, ...props }, ref) => {
  const { contentRef, setOpen } = usePopoverContext();
  const behaviour = usePopoverContext()?.behaviour;
  const isMouseDown = React.useRef(false);
  const isClickInside = React.useRef(false);

  React.useEffect(() => {
    const handleMouseDown = () => {
      isMouseDown.current = true;
    };

    const handleMouseUp = () => {
      isMouseDown.current = false;
    };

    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  React.useImperativeHandle(ref, () => contentRef.current);

  return (
    <Popover.Content
      side="bottom"
      // prevent autofocus on popovers for now since it gives them the outline
      onOpenAutoFocus={(e) => e.preventDefault()}
      ref={contentRef}
      onMouseEnter={callAll(behaviour === 'hover' && (() => !isMouseDown.current && setOpen?.(true)), props.onMouseEnter)}
      onMouseLeave={callAll(behaviour === 'hover' && (() => !isMouseDown.current && setOpen?.(false)), props.onMouseLeave)}
      data-popover-content
      onClickCapture={() => {
        isClickInside.current = true;
      }}
      className={cx(styles['popover-content'], fonts.hankenGrotesk.className, className)}
      {...props}
    >
      {children}
    </Popover.Content>
  );
};

export const Content = React.forwardRef(ContentRoot);
