/* eslint-disable no-param-reassign, no-restricted-syntax, no-await-in-loop, no-underscore-dangle */
// Doing a lot of manipulating of deeply nested storyblok content so this rule is painful:
import { createContext, useContext } from 'react';

import { StoryblokComponent, useStoryblokState } from '@storyblok/react';
import snakeCase from 'lodash/snakeCase';
import ErrorPage from 'next/error';
import { useRouter } from 'next/router';

import Loading from 'components/ui/loading';
import { isTestMode } from 'lib/ts-utils';

import { fetchApi } from './ht_api';
import logger from './logger';

const defaultLog = logger({ category: 'lib/storyblok' });

const StoryblokContext = createContext();
const useStoryblok = () => useContext(StoryblokContext);

const StoryBlokContent = ({ folder, initialStory, origin, ...props }) => {
  const router = useRouter();
  const category = `StoryBlokContent[${origin}]`;
  const story = useStoryblokState(initialStory);

  const fullSlug = [folder, story?.slug].filter((v) => !!v).join('/');
  defaultLog.silly('StoryBlokContent: story: %o, slug: %o, origin: %s, props: %o', story, fullSlug, origin, props);

  if (!router.isFallback && !fullSlug) {
    defaultLog.debug('%s: sending 404 for %s', category, fullSlug);
    return <ErrorPage statusCode={404} />;
  }

  if (!story.content) {
    defaultLog.debug('%s: waiting for content to load');
    return <Loading />;
  }

  return <StoryblokComponent blok={story.content} story={story} {...props} />;
};

const componentMatch = (test, real) => (real && test instanceof RegExp ? test.test(real) : test === real);

const findBloksRecursive = (blocks, component) => {
  const results = [];
  blocks
    .filter((b) => !!b)
    .forEach((b) => {
      if (componentMatch(component, b.component)) {
        results.push(b);
      }
      if (b.blocks) {
        results.push(...findBloksRecursive(b.blocks, component));
      } else if (typeof b === 'object') {
        Object.values(b)
          .filter((child) => !!child)
          .forEach((child) => {
            if (componentMatch(component, child.component)) {
              results.push(child);
            } else if (child instanceof Array) {
              results.push(...findBloksRecursive(child, component));
            }
          });
        /*
      // Feels dirty, but so far its either blocks or articles
      // Less dirty, just going through every array
      if (b.articles) {
        results.push(...findBloksRecursive(b.articles, component));
      }
      */
      }
    });
  return results;
};

const findBloks = (story, component) => {
  if (story?.blocks?.length) {
    return findBloksRecursive(story.blocks, component);
  }
  if (story?.content?.blocks?.length) {
    return findBloksRecursive(story.content.blocks, component);
  }
  return [];
};

const findBlok = (story, component) => findBloks(story, component)[0];

/*
const imageUrl = ({ image, size, origin }) => {
  if (image.imgix) {
    if (size) {
      return `https://${process.env.NEXT_PUBLIC_IMGIX_URL}/${image.imgix}?auto=format&w=${size}&h=${size}`;
    }
    return `https://${process.env.NEXT_PUBLIC_IMGIX_URL}/${image.imgix}`;
  }
  return normalizeUrl({ url: image, origin });
};
*/

const sbNav = async ({ log, version = 'published' }) => {
  log.info('Loading nav');
  const nav = await fetchApi({
    path: 'nav/nav',
    variables: { version },
    fallback: { error: true },
    origin: 'lib/storyblok.js#sbNav',
  });
  if (!nav || nav.error) {
    throw new Error(`Could not load components/nav from ${process.env.API_URL}`);
  }
  return nav.content;
};

// Get the featured products from the nav
const loadFeaturedProductsFromNav = ({ api, log }) => {
  log.debug('Loading featured products from nav');
  const { nav } = api;
  const container = nav.blocks.find((b) => b.component === 'nav-panel-container' && b.title === 'Hearing Aids');
  const panel = container.panels.find((p) => p.component === 'nav-panel' && p.title === 'Products');
  const tab = panel.panel_tabs.find((t) => t.component === 'nav-panel-tab' && t.title === 'Featured');
  const models = tab.items.map((i) => api.navModels[i.form_factor]).filter((i) => !!i);
  const products = models.map((m) => ({ ...m.release, models: [m] }));
  return products;
};

// The footer is defined as a story so we can load it on every page.
const sbFooter = async ({ story, log }) => {
  log.debug('Loading footer');
  const footer = await fetchApi({
    path: 'articles/story',
    variables: { slug: 'components/footer', version: 'published' },
    fallback: { error: true },
    origin: `lib/storyblok.js#sbFooter[${story?.slug}]`,
  });
  if (!footer || footer.error) {
    throw new Error(`Could not load components/footer from api ${process.env.API_URL}`);
  }
  const { content } = footer;
  // Some story pages are allowed to override this
  if (story?.content?.subscribe_image_override?.filename) {
    content.subscribe_image = story.content.subscribe_image_override;
  }
  log.silly('footer get: %o', content);
  const config = (await fetchApi({ path: 'settings/fetch/footer', fallback: {}, origin: `lib/storyblok.js#sbFooter[${story?.slug}]` })).value;
  return { content, config };
};

export const buildToc = ({ story }) => {
  const bloks = findBloks(story, /n4-markdown|n4-nested-blocks/);
  const wal = bloks.filter((m) => !!m.anchor_link);
  return wal.map((m) => ({ sectionId: snakeCase(m.anchor_link), title: m.anchor_link }));
};

const sbSidebar = async ({ story, version = 'published', log }) => {
  log.debug('Loading sidebar');
  const config = findBlok(story, 'n4-page-config');
  const { disable_sidebar_toc: noToc, disable_sidebar_articles: noArt, disable_sidebar_products: noProd } = config || {};
  if (noToc && noArt && noProd) {
    return null;
  }
  const sidebar = {};
  if (!noToc) {
    const toc = buildToc({ story });
    if (toc.length) {
      sidebar.toc = toc;
      return sidebar;
    }
  }
  if (noArt && noProd) {
    return null;
  }
  const sponsor = findBloks(story, 'n4-article').find((a) => !!a.sponsor)?.sponsor;
  const results = await fetchApi({
    path: 'nav/sidebar',
    variables: { sponsor, slug: story.slug, version, noArt, noProd },
    fallback: {},
    origin: 'lib/storyblok.js#sbSidebar',
  });
  if (!noProd) {
    sidebar.products = results.products;
  }
  if (!noArt) {
    sidebar.articles = results.articles;
  }
  return sidebar;
};

const loadMeta = async ({ story, props, version, log }) => {
  log.debug('Loading meta for %s', story.slug);
  const meta = await fetchApi({
    path: `articles/meta_from_storyblok.json?uuid=${story.uuid}`,
    variables: { version },
    fallback: {},
    origin: `lib/storyblok.js#loadMeta[${story?.slug}]`,
  });
  log.debug('meta: %o', meta);
  props.meta = meta;

  /* Should all be done in the api now
  meta.canonical = `https://www.hearingtracker.com${`/${folder}/${slug}`.replace(/\/\//g, '/').replace(/\/$/, '')}`;
  meta.open_graph_images = (meta.open_graph_images || []).map((image, idx) =>
    imageUrl({ image, size: idx === 0 ? undefined : 500, origin: 'lib/storyblok#meta.open-graph' })
  );
  meta.schema_images = (meta.schema_images || []).map((image, idx) =>
    imageUrl({ image, size: idx === 0 ? undefined : 500, origin: 'lib/storyblok#meta.open-graph' })
  );
  */

  // TODO: should we just stick the entire meta object into props or apidata?
  // Yes. Yes, we should.
  /*
  props.title = meta.title || story.name;
  props.hideBreadcrumbs = !!meta.hide_breadcrumbs;
  props.breadcrumbTitleOverride = meta.breadcrumb_title || '';
  props.open_graph_images = [];
  if (meta.image) {
    props.open_graph_images.push(meta.image); // TODO: is multiple really a thing?
  }
  props.description = meta.description || '';
  props.hideFooter = !!meta.hide_footer;
  props.fullWidth = !!meta.full_width;
  props.noindex = !!meta.noindex;
  */
};

// Should be obsolete
const convertStory = async ({ story, log }) => {
  if (story?.content?.component !== 'Builder') {
    return;
  }
  log.debug('Converting story: %s', story.slug);
  const tam = findBlok(story, 'Title and Meta');
  if (!tam) {
    return;
  }
  /*
   * content
   *  component: n4-container
   *  blocks
   *    n4-meta-information
   *      open_graph_image - same
   *      page_title - same
   *      meta_description - same
   *
   *    n4-article
   *      body
   *        n4-markdown
   *        n4-image
   *
   * content
   *  component: Builder
   *  blocks
   *    title_and_meta
   *    image
   *    markdown
   *    youtube
   *
   * title_and_meta -> n4-meta-information
   * remaining blocks -> move into n4-article body
   *   rename components
   *       markdown -> n4-markdown
   *       image -> n4-image
   */
  log.debug('Converting story: %s', story.slug);
  story.content.component = 'n4-container';
  const newBlocks = [];
  const meta = tam;
  meta.component = 'n4-meta-information';
  newBlocks.push(meta);

  const mapImage = (old) => {
    const newImage = {
      component: 'n4-image',
      caption: old.caption || '',
      alt_text: old.title_and_alt_text || '',
      image: {
        filename: old.url,
        fieldtype: 'asset',
      },
    };
    if (old.ink) {
      newImage.hyperlink = old.link;
    }
    return newImage;
  };

  let hero = null;
  if (tam.open_graph_image) {
    hero = {
      filename: tam.open_graph_image,
      alt: 'hero image',
      fieldtype: 'asset',
    };
  }
  const body = story.content.blocks
    .filter((b) => {
      if (b.component === 'Title and Meta') {
        return false;
      }
      if (b.component === 'Image') {
        if (hero) {
          if (hero.filename === b.url) {
            // og is same as first
            return false;
          }
          return true;
        }
        hero = {
          filename: b.url,
          alt: b.title_and_alt_text,
          fieldtype: 'asset',
        };
        return false;
      }
      return true;
    })
    .map((b) => {
      if (b.component === 'Image') {
        return mapImage(b);
      }
      return { ...b, component: `n4-${b.component.toLowerCase()}` };
    });

  const article = {
    component: 'n4-article',
    hero_image: hero,
    body,
    published: tam.published_on,
    updated: tam.updated_on,
    sponsor: tam.sponsor,
    primary_author: story.content.author,
    additional_authors: story.content.authors,
    expert_reviewers: story.content.medical_review,
  };
  newBlocks.push(article);
  story.content.blocks = newBlocks;
  // story.content.noindex;
  // story.content.hide_footer;
  // story.content.subscribe_image_override;
};

const loadArticles = async ({ story, folder, slug, api, log }) => {
  log.debug('Loading articles: %s', story.slug);
  const articles = {};
  api.articles = api.articles || {};
  // Article grids
  const articleGrids = findBloks(story, 'n4-article-grid');
  if (articleGrids.length) {
    log.debug('%s - %d article grids', slug, articleGrids.length);
    const fn = async (grid) => {
      if (grid.full_folder) {
        const gridFolder = story.is_startpage && slug ? `${folder}/${slug}` : folder;
        const path = 'articles/stories';
        const variables = { folder: gridFolder, slug, meta: true, version: 'published' };
        log.debug('Full folder - fetching stories: %s/%s -> %s %o', folder, slug, path, variables);
        const storiesInFolder = await fetchApi({
          path,
          variables,
          fallback: [],
          origin: `lib/storyblok.js#loadArticles-grids[${story?.slug}]`,
        });
        log.debug('Stories in folder %s: %d', gridFolder, storiesInFolder.length);
        // should we be modifying grid here or just populating articles
        // and having the component pull them in from useApiData? This
        // might break live editing in storyblok
        // Maybe just story like api.grids[_uid] = slugs and then pull articles from api like normal
        grid.blocks = storiesInFolder.map((s) => s.slug);
        storiesInFolder.forEach((meta) => {
          api.articles[meta.slug] = meta;
        });
      } else {
        log.debug('article grid slugs: %d', grid.slugs.length);
        grid.slugs.forEach((articleSlug) => {
          articles[articleSlug] = articleSlug;
        });
      }
      grid.blocks.forEach((articleSlug) => {
        // full folder has already loaded what it needs
        // so just pick up anything else (may not be any)
        if (!api.articles[articleSlug]) {
          articles[articleSlug] = articleSlug;
        }
      });
    };
    await articleGrids.reduce(async (previous, grid) => {
      await previous;
      return fn(grid);
    }, Promise.resolve('dummy'));
  } else {
    log.debug('No grids in story');
  }

  const articlesNeedingMeta = Object.keys(articles).filter((a) => !api.articles[a]);
  log.debug('articles needing meta: %d', articlesNeedingMeta.length);
  if (articlesNeedingMeta.length) {
    // Always load published versions of referenced articles
    const articlesWithMeta = await fetchApi({
      path: 'articles/meta_from_storyblok.json',
      variables: { slugs: articlesNeedingMeta.join(','), version: 'published' },
      fallback: {},
      origin: `lib/storyblok.js#loadArticles[${story?.slug}]`,
    });
    Object.assign(api.articles, articlesWithMeta);
    log.silly('api.articles for %s: %o', Object.keys(articles).join(','), api.articles);
  }
};

const loadAuthors = async ({ story, api, log }) => {
  log.debug('Loading authors: %s', story.slug);
  // n4 articles
  if (!api.authors) {
    api.authors = { '-1': { name: 'Staff' } };
  }
  const authors = [];
  const articleBloks = findBloks(story, 'n4-article');
  if (articleBloks.length) {
    articleBloks.forEach((article) => {
      let primary = article.primary_author;
      if (primary instanceof Array) {
        // eslint-disable-next-line prefer-destructuring
        primary = primary[0];
      }
      if (primary) {
        authors.push(primary);
      }
      authors.push(...(article.additional_authors || []));
      authors.push(...(article.expert_reviewers || []));
    });
  }

  const staffCards = findBloks(story, 'n4-staff-card');
  if (staffCards.length) {
    staffCards.forEach((card) => {
      authors.push(card.staff_member);
    });
  }
  const authorIds = (authors || []).filter((a) => !api.authors[a]);
  if (authorIds.length) {
    const authorData = await fetchApi({
      path: 'authors',
      variables: { authorIds },
      fallback: null,
      origin: `lib/storyblok.js#loadAuthors[${story?.slug}]`,
    });
    // api.authors.push(...Object.values(authorData));
    authorData.forEach((author) => {
      api.authors[author.id] = author;
    });
  }
};

/* If we ever want to convert imgix to b64 in the pre-render, here is how,
 * but it can get pretty big so only do it if you know it's a win.
const imgixToData = async (key) => {
  const args = {
    auto: 'format',
    fit: 'crop',
    crop: 'top,center',
    quality: 40,
    width: 600,
    height: 600,
  };
  const params = Object.entries(args)
    .reduce((a, [k, v]) => [...a, `${k}=${v}`], [])
    .join('&');
  const url = `https://${process.env.NEXT_PUBLIC_IMGIX_URL}/${key}?${params}`;
  const response = await fetch(url);
  const buffer = await response.arrayBuffer();
  const contentType = response.headers.get('content-type');
  const b64 = Buffer.from(buffer).toString('base64');
  return `data:${contentType};base64,${b64}`;
};
*/

// Load up the api data with any products that are on storyblok components
// These are stored as form factor slugs.
const loadProducts = async ({ story, api, log }) => {
  log.debug('Loading products: %s', story.slug);
  // Product cards
  if (!api.models) {
    api.models = {};
  }

  const formFactorsBySlug = {};
  const formFactorsById = {};
  const hearingAidsById = {};
  const productCards = findBloks(story, 'n4-product-card').concat(findBloks(story, 'n4-listicle-product'));
  let missing = false;
  (productCards || []).forEach((item) => {
    if (item.form_factor && !api.models[item.form_factor]) {
      missing = true;
      formFactorsById[item.form_factor] = {};
    } else if (item.hearing_aid && !api.models[`ha-${item.hearing_aid}`]) {
      missing = true;
      hearingAidsById[item.hearing_aid] = {};
    }
  });

  const homeHearingAids = findBloks(story, 'home-hearing-aid');
  (homeHearingAids || []).forEach((item) => {
    if (!api.models[item.form_factor]) {
      missing = true;
      formFactorsBySlug[item.form_factor] = {};
    }
  });
  if (!missing) {
    log.debug('No missing products');
    return;
  }
  const models = await fetchApi({
    path: 'releases/models',
    variables: { slugs: Object.keys(formFactorsBySlug), ids: Object.keys(formFactorsById), hearingAidIds: Object.keys(hearingAidsById) },
    fallback: {},
    origin: `lib/storyblok.js#loadProducts[${story?.slug}]`,
  });
  try {
    for (const model of Object.values(models)) {
      if (model) {
        api.models[model.slug] = model;
        api.models[model.id] = model; // product card uses form_factor_id instead of slug
        (model.hearing_aids || []).forEach((ha) => {
          api.models[`ha-${ha.id}`] = model;
        });
        /*
        if (model?.image?.imgix) {
          const b64 = await imgixToData(model.image.imgix);
          model.image.b64 = b64;
        }
        */
      }
    }
  } catch (ex) {
    console.error(ex);
  }
};

const loadRelease = async ({ story, api, log }) => {
  const productConfig = findBlok(story, 'n4-product-config');
  if (productConfig && productConfig.release) {
    if (api.release?.slug !== productConfig.release) {
      const path = `releases/${productConfig.release}`;
      log.debug('Loading release from productConfig: %s', path);
      api.release = await fetchApi({ path, fallback: null, origin: `lib/storyblok.js#loadRelease-productConfig[${story?.slug}]` });
      if (!api.release) {
        throw new Error(`Could not load release ${productConfig.release} from ${process.env.API_URL}`);
      }
      Object.entries(productConfig)
        .filter(([key]) => !/_editable|_uid|release|component/.test(key))
        .filter(([, value]) => (value instanceof Array ? value.length > 0 : value !== ''))
        .forEach(([key, value]) => {
          api.release[key] = value;
        });
      api.releasePage = true;
    } else {
      log.debug('api.release already loaded');
    }
    log.debug('api.release: %o', api.release?.slug);
  } else {
    log.debug('No product config');
  }
};

const loadAccessories = async ({ story, api, log }) => {
  log.debug('Loading accessories: %o', story.slug);
  const ids = findBloks(story, 'n4-accessory').map((b) => b.accessory_id);
  if (ids.length) {
    const path = 'releases/accessories';
    const results = await fetchApi({ path, variables: { ids: ids.join(',') }, fallback: {}, origin: `lib/storyblok.js#loadAccessories[${story?.slug}]` });
    api.accessories = results;
  }
};

const loadBrandReleases = async ({ story, api, log }) => {
  log.debug('Loading brand releases: %o', story.slug);
  const ids = findBloks(story, 'n4-brand-releases').map((b) => b.brand);
  if (ids.length) {
    if (!api.brandReleases) {
      api.brandReleases = {};
    }
    const missing = ids.filter((id) => !api.brandReleases[id]);
    if (missing.length) {
      const results = await fetchApi({
        path: 'brands/releases',
        variables: { ids: missing.join(',') },
        fallback: {},
        origin: `lib/storyblok.js#loadBrandReleases[${story?.slug}]`,
      });
      Object.entries(results).forEach(([id, releases]) => {
        api.brandReleases[id] = releases;
      });
    }
  }
};

const loadPartials = async ({ story, slug, variant, segments, api, log }) => {
  log.debug('Loading partials: %s', slug);
  const partials = findBloks(story, 'render_partial');
  if (!partials.length) {
    return;
  }
  api.partials = {};
  const fn = async (partial) => {
    if (partial.has_data) {
      log.debug('Loading partial data: %s', partial.partial_path);
      const partialProps = (await import(`components/bloks/partials/${partial.partial_path}/data`)).default;
      if (typeof partialProps === 'function') {
        api.partials[partial.partial_path] = await partialProps({ slug, variant, segments });
      } else {
        api.partials[partial.partial_path] = partialProps;
      }
    }
  };
  await partials.reduce(async (previous, partial) => {
    await previous;
    return fn(partial);
  }, Promise.resolve('dummy'));
};

const loadTags = async ({ api, log }) => {
  log.debug('Loading tags');
  api.tags = await fetchApi({
    path: 'settings/model_tags',
    fallback: [],
    origin: 'lib/storyblok.js#loadHome-tags',
  });
};

const loadHome = async ({ api, log }) => {
  log.debug('Loading home');
  api.releases = await fetchApi({
    path: 'directory',
    variables: { results_only: true },
    fallback: [],
    origin: 'lib/storyblok.js#loadHome-directory',
  });
};

const nonSbStaticProps = async ({ slug, log = defaultLog }) => {
  log.debug('Non storyblok page: %s', slug);
  const api = { cache: {} };
  const nav = await sbNav({ log, version: 'published' });
  // api.nav = nav;
  const results = {
    props: {
      api,
      nav,
      requestId: log.requestId,
      meta: {},
    },
  };
  // log.debug('nav: %o', nav);

  // Need this stuff for nav
  const promises = [loadArticles({ story: {}, folder: null, slug, api: results.props.api, log }), loadProducts({ story: {}, api: results.props.api, log })];

  await Promise.all(promises);

  results.props.api.footer = await sbFooter({ story: {}, log });
  log.debug('results: %o', results);
  return results;
};

const noAdHere = (paragraph) => {
  const t = paragraph.trim();
  return /^[-*] /.test(t) || /^#+ /.test(t) || /[0-9]\. /.test(t);
};

const markdownAd = ({ ad, media }) => {
  const url = new URL(ad.url);
  url.searchParams.append(
    'ad-attrs',
    JSON.stringify({
      'data-event-media': media,
      'data-event-type': ad.type,
    })
  );
  // Special format so markdown can optimize it
  // const imgUrl = `https://${process.env.NEXT_PUBLIC_IMGIX_URL}/${ad.image.imgix}?nativeWidth=${ad.image.native_width}&nativeHeight=${ad.image.native_height}`;
  // Note: the - ad part is used to tell markdown it should be lazy loaded, but then it's removed
  // `\n[![${ad.advertiser} image - ad](${imgUrl})](${url.toString()})\n`;
  // return `\n[![ad](${imgUrl})](${url.toString()})\n`;
  return `\n[](${url.toString()})\n`;
};

const insertAdInMarkdown = ({ markdown, desktopAd, mobileAd, context }) => {
  const ads = [];
  if (desktopAd) {
    ads.push(markdownAd({ ad: desktopAd, media: 'desktop' }));
  }
  if (mobileAd) {
    ads.push(markdownAd({ ad: mobileAd, media: 'mobile' }));
  }
  let added = false;
  if (ads.length) {
    context.log.silly('Got ads to add');
    const paras = markdown.content.split('\n\n');
    const len = paras.length;
    context.log.silly('ad paras: %o', paras);
    const newParas = [];
    for (let i = 0; i < len; ++i) {
      const para = paras[i];
      newParas.push(para);

      if (!added) {
        // const previousIsHeading = i === 0 || /^ *#/.test(paras[i - 1]);
        const currentIsHeading = /^ *#/.test(para) || i < len / 3;
        // don't put an ad before a heading or a bullet list
        const nextParaBad = i === len - 1 || noAdHere(paras[i + 1]);
        if (context.debug) {
          newParas.push(`\n\nwants an ad i: ${i}, cur bad: ${currentIsHeading} next bad: ${nextParaBad} len: ${len}\n\n`);
        }
        if (/* previousIsHeading || */ !(currentIsHeading || nextParaBad)) {
          // newParas.push(`\n\nwants an ad i: ${i}, cur: ${currentIsHeading} next: ${nextParaBad} len: ${len}\n\n`);
          newParas.push(...ads);
          added = true;
        }
      }

      /*
        const isHeading = /^ *#/.test(para);
        const borders = (len > 3 && i === 0) || i === len - 1;
        if (!borders) {
          context.log.silly('was %o, is %o', wasHeading, isHeading);
          //if (!wasHeading && !isHeading) {
          if (!isHeading) {
            newParas.push(...ads);
          newParas.push(`\n\ngot an ad i: ${i}, wasHeading: ${wasHeading} is: ${isHeading} borders: ${borders} len: ${len} start: ${start}\n\n`);
            added = true;
          } else {
          newParas.push(`\n\nwants an ad but i: ${i}, wasHeading: ${wasHeading} is: ${isHeading} borders: ${borders} len: ${len} start: ${start}\n\n`);
          }
        } else {
          newParas.push(`\n\nwants an ad but i: ${i}, wasHeading: ${wasHeading} is: ${isHeading} borders: ${borders} len: ${len} start: ${start}\n\n`);
        }
        wasHeading = isHeading;
      }
      */
    }
    // if (added) {
    markdown.content = newParas.join('\n\n');
    // }
  } else {
    context.log.debug('No ads to add');
  }
  return added;
};

const adBlockCheck = ({ blocks, context }) => {
  const newBlocks = [];
  blocks.forEach((blok) => {
    newBlocks.push(blok);
    if (blok.component === 'n4-markdown') {
      context.lines += blok.content.split(' ').length / context.wordsPerLine;
    } else if (blok.component === 'n4-pros-and-cons') {
      context.lines += (blok.cons || '').split(' ').length / context.wordsPerLine + (blok.pros || '').split(' ').length / context.wordsPerLine;
    } else {
      context.lines += context.nonTextLines;
    }
    context.log.debug('%o', { context, component: blok.component });
    if (blok.component === 'n4-markdown') {
      if (context.debug) {
        blok.content = `${blok.content}\nEND OF MARKDOWN line: ${context.lines} lines, ${context.adCount} ads words: ${blok.content.split(' ').length}`;
      }
      if (context.lines >= context.linesPerAd) {
        context.log.debug('trying to add and ad');
        const desktopAd = context.desktopAds[context.adCount % context.desktopAds.length];
        const mobileAd = context.adCount % 2 === 0 ? context.mobileAds[context.adCount % context.mobileAds.length] : null;
        if (insertAdInMarkdown({ markdown: blok, desktopAd, mobileAd, context })) {
          context.log.debug('added an ad');
          // Start counting again after we inject an ad.
          context.lines = 0;
          // Rotate through the ads, may just have one if it's tied to an advertiser, or
          // multiple if it's not.
          context.adCount += 1;
        } else {
          context.log.debug('failed to add an ad');
        }
      }
    } else if (blok.component === 'n4-nested-blocks') {
      blok.blocks = adBlockCheck({ blocks: blok.blocks, context });
    }
  });
  return newBlocks;
};

const injectAds = async ({ story, log }) => {
  const article = findBlok(story, 'n4-article');
  if (!article || article.hide_ads) {
    return;
  }
  log.debug('Injecting ads');
  const { advertiser, sponsor } = article;
  log.debug('sponsor/advertiser: %o', { advertiser, sponsor });

  const slug = story.content.blocks.find((b) => b.component === 'n4-product-config')?.release;

  const desktopAds = (
    await fetchApi({
      path: 'nav/ads',
      variables: { sponsor, advertiser, count: 10, ad_type: 'Leaderboard,Tall Leaderboard', slug, test_mode: isTestMode },
      origin: 'lib/storyBloks.js#injectAds',
      log,
    })
  ).ads;
  const mobileAds = (
    await fetchApi({
      path: 'nav/ads',
      variables: { sponsor, advertiser, count: 10, ad_type: 'Large Rectangle', slug, test_mode: isTestMode },
      origin: 'lib/storyBloks.js#injectAds',
      log,
    })
  ).ads;

  if (!desktopAds.length && !mobileAds.length) {
    return;
  }

  /*
  // Very rough heuristic for how often to show an ad
  const linesPerAd = 25;
  // Another rough heuristic for how many words are on a line
  // in an article;
  const wordsPerLine = 20;
  // Yet another one for how large to treat things other than markdown
  // and pros-and-cons, e.g. images, videos, tables. We can go to town
  // figuring it out for each possible component if we want.
  const nonTextLines = 20;

  log.silly('%o', { desktopAds, mobileAds });
  let lines = 10; // start with an ad a little early
  let adCount = 0;
  const newBlocks = [];
  let currentBlok;
  */
  try {
    const context = {
      debug: false,
      linesPerAd: 25,
      wordsPerLine: 15,
      nonTextLines: 20,
      lines: 10,
      adCount: 0,
      desktopAds,
      mobileAds,
      log,
    };
    article.body = adBlockCheck({ context, blocks: article.body });
    /*
    article.body.forEach((blok) => {
      currentBlok = blok;
      newBlocks.push(blok);
      if (blok.component === 'n4-markdown') {
        lines += blok.content.split(' ').length / wordsPerLine;
      } else if (blok.component === 'n4-pros-and-cons') {
        lines += (blok.cons || '').split(' ').length / wordsPerLine + (blok.pros || '').split(' ').length / wordsPerLine;
      } else {
        lines += nonTextLines;
      }
      log.debug('%o', { lines, adCount, component: blok.component });
      if (lines >= linesPerAd) {
        log.debug('trying to add and ad');
        if (blok.component === 'n4-markdown') {
          blok.content = `${blok.content}\nEND OF MARKDOWN line: ${lines} adCount: ${adCount}`;
          const desktopAd = desktopAds[adCount % desktopAds.length];
          const mobileAd = adCount % 2 === 0 ? mobileAds[adCount % mobileAds.length] : null;
          if (insertAdInMarkdown({ markdown: blok, desktopAd, mobileAd, log })) {
            log.debug('added an ad');
            // Start counting again after we inject an ad.
            lines = 0;
            // Rotate through the ads, may just have one if it's tied to an advertiser, or
            // multiple if it's not.
            adCount += 1;
          } else {
            log.debug('failed to add an ad');
          }
        }
      }
      if (blok.component === 'n4-nested-blocks') {
        const newNestedBlocks = [];
        blok.blocks.forEach((nestedBlok) => {
          if (nestedBlok.component === 'n4-markdown') {
            lines += nestedBlok.content.split(' ').length / wordsPerLine;
          } else if (nestedBlok.component === 'n4-pros-and-cons') {
            lines += (nestedBlok.cons || '').split(' ').length / wordsPerLine + (nestedBlok.pros || '').split(' ').length / wordsPerLine;
          } else {
            lines += nonTextLines;
          }
          newNestedBlocks.push(nestedBlok);
          log.debug('%o', { lines, adCount, component: nestedBlok.component });
          if (lines >= linesPerAd) {
            log.debug('trying to add and ad');
            if (nestedBlok.component === 'n4-markdown') {
              nestedBlok.content = `${nestedBlok.content}\nEND OF NESTED MARKDOWN line: ${lines} adCount: ${adCount}`;
              const desktopAd = desktopAds[adCount % desktopAds.length];
              const mobileAd = adCount % 2 === 0 ? mobileAds[adCount % mobileAds.length] : null;
              if (insertAdInMarkdown({ markdown: nestedBlok, desktopAd, mobileAd, log })) {
                log.debug('added an ad');
                // Start counting again after we inject an ad.
                lines = 0;
                // Rotate through the ads, may just have one if it's tied to an advertiser, or
                // multiple if it's not.
                adCount += 1;
              } else {
                  log.debug('failed to add an ad');
                }
              }
            }
          });
        blok.blocks = newNestedBlocks;
      }
      */

    // Adding both mobile and desktop since this is happening at build time
    // css will hide as appropriate.
    /*
        const ad = desktopAds[adCount % desktopAds.length];
        const mobileAd = mobileAds[adCount % mobileAds.length];
        newBlocks.push({
          _uid: `d-${ad.id}-${blok._uid}`, // to avoid duplicate key issue
          component: 'n4-image',
          alt: ad.advertiser,
          title: ad.advertiser,
          image: ad.image,
          classname: 'desktop-ad-auto',
          dataset: `advertiser-id=${ad.advertiser_id},type=${ad.type},image=${ad.filename}`,
          hyperlink: {
            cached_url: ad.url,
          },
        });
        newBlocks.push({
          _uid: `m-${ad.id}-${blok._uid}`, // to avoid duplicate key issue
          component: 'n4-image',
          alt: mobileAd.advertiser,
          title: mobileAd.advertiser,
          image: mobileAd.image,
          classname: 'mobile-ad-auto',
          dataset: `advertiser-id=${ad.advertiser_id},type=${ad.type},image=${ad.filename}`,
          hyperlink: {
            cached_url: ad.url,
          },
        });
        */
    /* If we want to treat it as storyblok blok instead of a storyblok image, it would look like this:
        const adBlok = {
          component: 'n4-ad',
          alt: 'Generic Ad',
          url: 'https://www.hearingtracker.com',
          image: {
            id: 999999,
            filename: 'https://a.storyblok.com/f/45415/1536x190/435f8a498c/banner_ad.webp',
            fieldtype: 'asset',
            is_external_url: false,
          },
        };
      }
    });
      */
    log.debug('Injected ads: %d', context.adCount);
    // If no ads, try to stick one in the middle of a markdown
    // Note: this requires are markdown component to be aware of the ad-attrs
    // parameter and strip it off off the url.
    // TODO: I guess handle situations where there are multiple markdowns,
    // but doubtful that's the case if we're under 100 lines total.
    if (context.adCount === 0) {
      const bloks = findBloks(story, 'n4-markdown');
      if (bloks.length === 1) {
        const dad = desktopAds[0];
        const mad = mobileAds[0];
        const ads = [];
        if (dad) {
          ads.push(markdownAd({ ad: dad, media: 'desktop' }));
        }
        if (mad) {
          ads.push(markdownAd({ ad: mad, media: 'mobile' }));
        }
        if (ads.length) {
          const mdLines = bloks[0].content.split('\n');
          const newLines = [...mdLines.slice(0, mdLines.length / 2), ...ads, ...mdLines.slice(mdLines.length / 2)];
          bloks[0].content = newLines.join('\n');
        }
      }
    }
  } catch (ex) {
    log.error('Error in ads: %o', ex);
    // log.error(currentBlok);
  }
  // article.body = newBlocks;
};

// If sync is true, the API will load the story fresh from the Storyblok API and put it in our cache.
// This will happen when the _storyblok parameter, so we know they just saved while editing
const sbStaticProps = async ({ folder, slug, variant = 'A', segments, version, origin, log = defaultLog, injector }) => {
  // eslint-disable-next-line no-console
  console.log('server env: %s', process.env.SERVER_ENV);
  // fetch things from api that are used on all pages
  const parts = [folder, slug].filter((p) => !!p && p !== '/');
  const fullSlug = parts.join('/');
  log.info('\n\n%s start of %s %s\n\n', '-'.repeat(30), fullSlug, '-'.repeat(30));
  log.info('%s: loading story folder [%s] slug [%s] version: %s', origin, folder, slug === '/' ? '' : slug, version);
  log.info('fullSlug: %s', fullSlug);

  try {
    const story = await fetchApi({
      path: 'articles/story',
      variables: { slug: fullSlug, version },
      origin: `lib/storyblok.js#sbStaticProps[${fullSlug}]`,
      log,
    });
    if (!story || story.error) {
      if (fullSlug.startsWith('press-releases')) {
        // we are letting all press-releases urls get this far so it's expected that some will not be valid
        log.debug('No story for: %s', fullSlug);
      } else {
        log.error('No story for: %s', fullSlug);
        log.info('\n\n%s end of not found %s %s\n\n', '-'.repeat(30), fullSlug, '-'.repeat(30));
      }
      return { notFound: true };
    }
    log.info('Found story for %s', fullSlug);

    // TODO: remove this once we're sure all stories have been converted
    convertStory({ story, log });

    const api = { cache: {} };
    if (injector) {
      await injector({ story, api, log, origin });
    }

    const results = {
      props: {
        version,
        title: story.name,
        story,
        api,
        requestId: log.requestId,
      },
      revalidate: 12 * 60 * 60, // because aws urls expire
    };

    // These functions go through the story and retrieve data from the api that corresponds to story fields.
    // For example, if a story has a product, it would just be a slug in storyblok, and this will hit the
    // api to get the full product for that slug.
    // TODO: could maybe write an uber-api that does all this in one fetch
    const promises = [
      loadMeta({ story, props: results.props, version, log }),
      loadArticles({ story, folder, slug, api: results.props.api, log }),
      loadProducts({ story, api: results.props.api, log }),
      loadAuthors({ story, api: results.props.api, log }),
      loadPartials({ story, slug, variant, segments, api: results.props.api, log }),
      loadRelease({ story, slug, variant, segments, api: results.props.api, log }),
      loadAccessories({ story, api: results.props.api, log }),
      loadBrandReleases({ story, api: results.props.api, log }),
      loadTags({ api: results.props.api, log }),
    ];
    const nav = await sbNav({ log, version }); // TODO: using published until I optimize draft mode better
    results.props.nav = nav;

    // TODO: kind of ugly, maybe make this a partial
    if (slug === 'home') {
      promises.push(loadHome({ api: results.props.api, log }));
    } else if (!api.releasePage) {
      results.props.sidebar = await sbSidebar({ story, version, log });
    }
    await Promise.all(promises);

    if (folder === 'components' && slug === 'footer') {
      results.props.api.footer = {
        content: story.content,
        config: (await fetchApi({ path: 'settings/fetch/footer', fallback: {}, origin: `lib/storyblok.js#footer[${fullSlug}]`, log })).value,
      };
    } else if (!results.props.hideFooter) {
      results.props.api.footer = await sbFooter({ story, log }); // { footer: ..., config: ... }
    }
    await injectAds({ story, log });

    log.silly('%s: results: %o', origin, results);
    log.info('%s: done loading story %s/%s', origin, folder, slug);
    log.info('\n\n%s end of %s %s\n\n', '-'.repeat(30), fullSlug, '-'.repeat(30));
    return results;
  } catch (ex) {
    log.error('%s: error getting %s: %s. Returning notFound.', origin, fullSlug, ex.message);
    log.error(ex.stack);
    log.info('\n\n%s end of exception %s %s\n\n', '-'.repeat(30), fullSlug, '-'.repeat(30));
    log.info('');
    return { notFound: true };
  }
};

export {
  StoryBlokContent,
  StoryblokContext,
  findBlok,
  findBloks,
  loadAuthors,
  loadBrandReleases,
  loadFeaturedProductsFromNav,
  loadProducts,
  loadRelease,
  nonSbStaticProps,
  sbSidebar,
  sbStaticProps,
  useStoryblok,
};
