import { storyblokEditable } from '@storyblok/react';

import partials from '../partials';

const RenderPartial = ({ blok, ...props }) => {
  const partialPath = blok.partial_path;
  if (partialPath) {
    const Component = partials[partialPath];
    return <Component {...storyblokEditable} blok={blok} {...props} />;
  }
  return null;
};

export default RenderPartial;
