import { useEffect, useState } from 'react';

import type { SbBlokData } from '@storyblok/react';

import { useApiData } from 'hooks/use_api_data';
import { fetchApi } from 'lib/ht_api';

import AccessoryCard from './accessory-card';

interface Blok extends SbBlokData {
  accessory_id: number | number[];
  description_override?: string;
}

interface ApiAccessory {
  id: number;
  slug: string;
  description?: string;
  error?: string;
}

const Accessory = ({ blok }: { blok: Blok }) => {
  const accessoryId = blok.accessory_id instanceof Array ? blok.accessory_id[0] : blok.accessory_id;
  const { accessories } = useApiData().api;
  const [accessory, setAccessory] = useState((accessories || {})[accessoryId]);

  // Note: this is for storyblok live editing; that's the only
  // time the slug should change on the fly.
  useEffect(() => {
    if (!accessoryId || (accessory && accessory.id === accessoryId)) {
      return;
    }
    if (accessories[accessoryId]) {
      setAccessory(accessories[accessoryId]);
      return;
    }
    const fn = async () => {
      const path = `releases/accessories?ids=${accessoryId}`;
      const results: ApiAccessory[] = await fetchApi({
        path,
        variables: { ids: [accessoryId] },
        fallback: {},
        origin: 'nav-expanded/bloks/n4/accessory',
      });
      const acc = Object.values(results)[0];
      if (acc && !acc.error) {
        setAccessory(acc);
      }
    };
    fn();
  }, [accessoryId, accessory, accessories]);

  if (!accessory) {
    return null;
  }

  const description = blok.description_override || accessory.description;
  const accessoryWithOverride = { ...accessory, description };

  return <AccessoryCard {...accessoryWithOverride} blok={blok} />;
};

export default Accessory;
