import React from 'react';

import { fetchApi } from 'lib/ht_api';
import logger from 'lib/logger';
import type { Offer, Price } from 'types/release';

import Currency from './currency';

const log = logger({ category: 'Price' });

export interface PriceProps {
  releaseSlug: string;
  modelId?: number;
  levelId?: number;
  hearingAidId?: number;
  hearingAidSlug?: string;
  className?: string;
  loading?: JSX.Element | string;
  prefix?: JSX.Element | string;
  suffix?: JSX.Element | string;
  notFoundMessage?: JSX.Element | string;
  price?: Offer | Price;
  noGeo: boolean;
  origin: string;
}

interface NoPrice {
  message: JSX.Element | string;
}

const PriceTrigger: React.FC<PriceProps> = ({
  releaseSlug,
  modelId,
  levelId,
  hearingAidId,
  hearingAidSlug,
  loading,
  prefix,
  suffix,
  notFoundMessage,
  price,
  noGeo,
  className,
  origin,
}) => {
  const [geoPrice, setGeoPrice] = React.useState<Offer | Price | NoPrice | undefined>(price);

  React.useEffect(() => {
    if (!price && noGeo) {
      setGeoPrice({ message: notFoundMessage || 'Price Not Available' });
    }
    // Do not get geocoded prices if price is passed in
    if (price) {
      setGeoPrice(price);
      return;
    }
    const fn = async () => {
      log.debug('getting price for: %o', { releaseSlug, modelId, levelId, hearingAidId, hearingAidSlug });
      setGeoPrice(undefined);
      const result: Offer = await fetchApi({
        path: `/releases/${releaseSlug}/price`,
        variables: { hearingAidId, hearingAidSlug, levelId, modelId },
        fallback: {},
        origin: `widgets/price.js[${origin}]`,
      });
      log.debug('price result for %o: %o', { releaseSlug, modelId, levelId, hearingAidId, hearingAidSlug }, result);
      if (result.price) {
        setGeoPrice(result);
      } else {
        setGeoPrice({ message: notFoundMessage || 'Price Not Available' });
      }
    };
    fn();
  }, [releaseSlug, modelId, levelId, hearingAidId, hearingAidSlug, notFoundMessage, price, noGeo, origin]);

  if (!geoPrice) {
    return loading || null;
  }

  if ((geoPrice as NoPrice).message) {
    return <span>{(geoPrice as NoPrice).message}</span>;
  }

  const validPrice = geoPrice as Offer;

  return (
    <span className={className}>
      {prefix || ''}
      <Currency price={validPrice.price} fallback={'N/A'} />
      {/pair/.test(validPrice.priceType) && ' / pair'}
      {/month/.test(validPrice.priceType) && ' / mth'}
      {suffix || ''}
    </span>
  );
};

export default PriceTrigger;
