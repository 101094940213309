import * as React from 'react';

import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import debounce from 'lodash/debounce';
import NextLink from 'next/link';

import { HEARING_AID_MODEL_SCORE_TOTAL } from 'components/common/constants.js';
import { Button } from 'components/common-n4/button';
import { CircleScore } from 'components/common-n4/circle-score';
import ImageWrapper from 'components/common-n4/image';
import * as Popover from 'components/common-n4/popover';
import SvgIcon from 'components/ui/svg_icon';
import Currency from 'components/widgets/currency';
import { useIsMediumOrMobileView } from 'hooks/use_is_mobile_view';
import { fetchApi } from 'lib/ht_api';
import { normalizeUrl } from 'lib/utils';

import styles from './search.module.scss';

const ReactMarkdown = React.lazy(() => import('react-markdown'));

const Markdown = ({ children }) => (
  <React.Suspense fallback={null}>
    <ReactMarkdown>{children}</ReactMarkdown>
  </React.Suspense>
);

let searchingTimer = null;
export const NavSearch = ({ isSearch, handleResetSearch, searchTerm: mobileMenuSearchTerm, initialPanels, story }) => {
  const searchRef = React.useRef(null);
  // Used to ensure when setting results, we are setting them based on the current search term
  // Because it's async, we could be getting a newer search result back before an older one.
  const termRef = React.useRef(null);
  const formWrapperRef = React.useRef(null);
  const resultRef = React.useRef(null);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchResults, setSearchResults] = React.useState(null);
  const [searching, setSearching] = React.useState(false);
  const isMediumOrMobileView = useIsMediumOrMobileView();
  const calledInitialMobileSearch = React.useRef(false);
  const expand =
    story?.full_slug?.endsWith('components/nav') &&
    !/no-expand/.test(story.content.debug) &&
    (typeof window === 'undefined' || !/no-expand/.test(window.location.search));
  const { close } = Popover.usePopoverContext(expand);

  termRef.current = searchTerm;

  // if user entered search into initial mobile menu
  React.useEffect(() => {
    setSearchTerm(mobileMenuSearchTerm || '');
  }, [mobileMenuSearchTerm]);

  // debounce search request
  const debouncedSearch = React.useMemo(
    () =>
      debounce(async (search) => {
        if (!search) {
          setSearchResults(null);
          return;
        }

        if (searchingTimer) {
          clearTimeout(searchingTimer);
          searchingTimer = null;
        }
        setSearching(true);
        try {
          const data = await fetchApi({ path: 'nav/search', variables: { q: search }, origin: 'n4/nav/search.js' });
          if (termRef.current === search) {
            setSearchResults(data ? { articles: data.articles, products: data.products, term: search } : null);
          }
        } finally {
          // Give it a little delay so the render can happen
          searchingTimer = setTimeout(() => setSearching(false), 300);
        }
      }, 150),
    [termRef]
  );

  React.useEffect(() => {
    if (isMediumOrMobileView) {
      const fetchData = async () => {
        if (searchingTimer) {
          clearTimeout(searchingTimer);
          searchingTimer = null;
        }
        setSearching(true);
        try {
          const data = await fetchApi({ path: 'nav/search', variables: { q: searchTerm }, origin: 'n4/nav/search.js' });
          if (termRef.current === searchTerm) {
            setSearchResults(data ? { articles: data.articles, products: data.products, term: searchTerm } : null);
          }
        } finally {
          searchingTimer = setTimeout(() => setSearching(false), 200);
        }
      };

      // avoid 250 lag on first search because it feels longer
      if (!calledInitialMobileSearch.current && mobileMenuSearchTerm) {
        calledInitialMobileSearch.current = true;
        fetchData();
        return undefined;
      }

      if (!searchTerm) {
        setSearchResults(null);
        // handleResetSearch?.();
        return undefined;
      }

      debouncedSearch(searchTerm);
    }

    if (!searchTerm) {
      setSearchResults(null);
      return undefined;
    }

    debouncedSearch(searchTerm);

    return () => {
      debouncedSearch?.cancel();
    };
  }, [mobileMenuSearchTerm, searchTerm, debouncedSearch, isMediumOrMobileView, handleResetSearch, termRef]);

  /* If we want to track impressions on these
  const featuredSlugs = React.useMemo(() => (searchResults?.products?.results?.slice(0, 10) || []).map((p) => p.slug), [searchResults?.products?.results]);

  React.useEffect(() => {
    featuredSlugs.forEach((slug) => {
      const properties = {
        release: slug,
      };
      track({ event: `search-product--impression`, eventType: 'impression', origin: window.location.pathname, properties });
    });
  }, [featuredSlugs]);
  */

  const handleSearchTerm = (event) => {
    event.preventDefault();
    setSearchTerm(event.target.value);
  };

  // focus on search when open
  React.useEffect(() => {
    if (!isSearch || !searchRef.current) {
      return;
    }

    searchRef.current.focus();
  }, [isSearch]);

  // adds shadow when results are scrollable
  React.useEffect(() => {
    if (!formWrapperRef.current || !resultRef.current) {
      return undefined;
    }

    const resultDiv = resultRef.current;

    const handleScroll = () => {
      // make sure shadow only renders at the bottom, remove shadow when scrolled all the way up
      formWrapperRef.current.style.boxShadow = resultDiv.scrollTop === 0 ? 'none' : '0 20px 24px -20px rgba(0, 0, 0, 0.15)';
    };

    resultDiv.addEventListener('scroll', handleScroll);

    return () => {
      resultDiv?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // const articleCount = searchResults?.articles?.count || 0;
  // const productCount = searchResults?.products?.count || 0;
  const articles = searchResults?.articles?.results;
  // should not get null slugs but just in case
  const products = searchResults?.products?.results.filter((p) => !!p.slug).slice(0, 10);
  // even if its empty, this allows passed search results to show while current ones are being handled
  const hasSearchResults = articles || products;
  const renderInitialPanels = initialPanels?.length && !hasSearchResults;
  const fullPlaceholder = 'Search hearing products, reviews, and more';
  const truncatedPlaceholder = 'Search hearing products';

  const innerWidth = typeof window === 'undefined' ? 640 : window.innerWidth;

  return (
    <section className={styles['nav-search']}>
      <div ref={formWrapperRef} className={styles['form-wrapper']}>
        <section className={styles.form}>
          <div>
            {searching && <SvgIcon icon="circle-notch" aria-hidden className={styles['magnifying-glass']} spin />}
            {!searching && <SvgIcon icon="magnifying-glass-02" aria-hidden className={styles['magnifying-glass']} />}
          </div>

          <input
            ref={searchRef}
            name="nav-search"
            value={searchTerm}
            onChange={handleSearchTerm}
            placeholder={innerWidth < 640 ? truncatedPlaceholder : fullPlaceholder}
            className="placeholder:text-gray-500"
          />
        </section>
      </div>
      <section ref={resultRef} className={styles.result}>
        {renderInitialPanels ? (
          <section className={styles.initial}>
            {initialPanels.slice(0, 4).map((panel) => (
              <StoryblokComponent blok={panel} story={story} key={panel?._uid} {...storyblokEditable(panel)} />
            ))}
          </section>
        ) : null}

        {hasSearchResults ? (
          <section className={styles.data}>
            <section className={styles.articles}>
              <section className={styles['articles-top']}>
                <section className={styles['articles-top--left']}>
                  <h4>Articles</h4>
                  {/*
                  <NextLink variant="secondary" href={`/search?q=${searchTerm}`}>
                    <span>
                      {articleCount} Result{articleCount > 1 || articleCount === 0 ? 's' : ''}
                    </span>
                  </NextLink>
                  */}
                </section>
                {/* 
                  <NextLink variant="secondary" href={`/search?q=${searchTerm}`}>
                    View all
                  </NextLink>
                */}
              </section>
              <section className={styles['articles-bottom']}>
                {/* article list */}
                {articles?.length ? (
                  <section className={styles['articles-data']}>
                    {articles.map((article) => (
                      <NextLink
                        prefetch={false}
                        key={article.id}
                        href={normalizeUrl({ url: article.slug, origin: 'nav/search' })}
                        rel={article.noindex ? 'nofollow' : undefined}
                        style={{ display: 'contents' }}
                        onClick={close}
                      >
                        <section className={styles.article}>
                          <section className={styles['article--left']}>
                            <figure>
                              {article.image ? (
                                <ImageWrapper
                                  image={article.image}
                                  imgProps={{ loading: 'eager' }}
                                  sizingProps={{ maxWidth: 320 }}
                                  origin="nav-search-article"
                                />
                              ) : (
                                <div className={styles.placeholder} />
                              )}
                            </figure>
                            <section className={styles.details}>
                              <h5>
                                <Markdown>{article.title}</Markdown>
                              </h5>
                              <Markdown>{article.blurb}</Markdown>
                            </section>
                          </section>
                          <section className={styles['article--right']}>
                            <span>Read more</span>
                            <div className={styles.icon}>
                              <SvgIcon icon="arrow-right-regular" />
                            </div>
                          </section>
                        </section>
                      </NextLink>
                    ))}
                  </section>
                ) : (
                  <div className={styles['no-results']}>
                    <section className={styles.content}>
                      <section className={styles['no-results-details']}>
                        <p>Sorry are we missing something?</p>
                        <span>Get in touch and let us know.</span>
                      </section>
                      <Button.LinkTertiary href="/contact" shouldOpenNewTab={false}>
                        Contact us
                      </Button.LinkTertiary>
                    </section>
                  </div>
                )}
              </section>
            </section>
            <section className={styles.products}>
              <section className={styles['products-top']}>
                <section className={styles['products-top--left']}>
                  <h4>Products</h4>
                  {/*
                  <NextLink variant="secondary" href={`/search?q=${searchTerm}`}>
                    <span>
                      {productCount} Result{productCount > 1 || productCount === 0 ? 's' : ''}
                    </span>
                  </NextLink>
                  */}
                </section>
                {/*
                  <NextLink variant="secondary" href={`/search?q=${searchTerm}`}>
                    View all
                  </NextLink>
                */}
              </section>
              <section className={styles['products-bottom']}>
                {/* products list */}
                {products?.length ? (
                  products.map((product) => (
                    <NextLink
                      prefetch={false}
                      key={product.id}
                      href={normalizeUrl({ url: product.slug, origin: 'nav/search#bottom' })}
                      rel={product.noindex ? 'nofollow' : undefined}
                      style={{ display: 'contents' }}
                      onClick={close}
                    >
                      <section className={styles.product}>
                        <figure>
                          {product.image.imgix ? (
                            <>
                              <ImageWrapper
                                image={product.image}
                                imgProps={{ alt: product.title, loading: 'eager' }}
                                sizingProps={{ maxWidth: 320 }}
                                origin="nav-searcH-product"
                              />
                              {product.score && !isMediumOrMobileView ? (
                                <CircleScore
                                  progress={(product.score / HEARING_AID_MODEL_SCORE_TOTAL) * 100}
                                  amount={product.score}
                                  isRight
                                  isSmall
                                  origin="nav/search"
                                />
                              ) : null}
                            </>
                          ) : (
                            <div className={styles.placeholder} />
                          )}
                        </figure>
                        <section className={styles.details}>
                          <h5>
                            <Markdown>{product.name}</Markdown>
                          </h5>
                          <Markdown>{product.blurb}</Markdown>
                          <div className={styles.price}>
                            <Currency
                              price={product.price?.price}
                              fallback="Price not available"
                              prefix="From "
                              suffix={/month/.test(product.price?.priceType) ? ' / pair / mth' : ' / pair'}
                            />
                          </div>
                        </section>
                      </section>
                    </NextLink>
                  ))
                ) : (
                  <div className={styles['no-results']}>
                    <section className={styles.content}>
                      <section className={styles['no-results-details']}>
                        <p>Have a product you’d like us to test?</p>
                        <span>Get in touch and let us know.</span>
                      </section>
                      <Button.LinkTertiary href="/contact" shouldOpenNewTab={false}>
                        Contact us
                      </Button.LinkTertiary>
                    </section>
                  </div>
                )}
              </section>
            </section>
          </section>
        ) : null}
      </section>
    </section>
  );
};
