import '../styles/site.scss';
import '../styles/tailwind.css';
import { useEffect, useMemo, useState } from 'react';

// import { GoogleAnalytics } from '@next/third-parties/google';
import { apiPlugin, storyblokInit } from '@storyblok/react';
import { useRouter } from 'next/router';
// import Plausible from 'plausible-tracker';
import Ping from 'ping.js';
import ph from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import n4Components from 'components/bloks/n4';
import InProgress from 'components/bloks/n4/in-progress';
import { AudioProvider } from 'components/common-n4/audio/audio_context';
import ErrorBoundary from 'components/layout/error-boundary';
import GlobalErrorBoundary from 'components/layout/global-error-boundary';
import Layout from 'components/layout/n4_layout';
import { ApiDataContext, FeatureFlagsProvider, OncePerPageContextProvider, SessionContext } from 'hooks';
import { getCookie, eraseCookie } from 'lib/cookies';
import { fetchApi } from 'lib/ht_api';
import logger from 'lib/logger';
import { StoryblokContext } from 'lib/storyblok';
import { track, startTrackingObserver, stopTrackingObserver } from 'lib/tracking';
import { isDevelopment } from 'lib/ts-utils';

const log = logger({ category: 'app' });

const DISABLE_ERROR_BOUNDARY = isDevelopment;

// config.autoAddCss = false;

// Note can't use destructuring here see:
//   https://nextjs.org/docs/api-reference/next.config.js/environment-variables

// This will be the preview token in localhost, preview, and edit and the
// published-only token in production.
// Published token can only see published, preview can see draft and published
// I guess this is so you don't accidentally show draft in your production environment.
const accessToken = process.env.STORYBLOK_SECRET;
log.info('Storyblok access token: %s', accessToken);

storyblokInit({
  accessToken,
  use: [apiPlugin],
  enableFallbackComponent: true,
  customFallbackComponent: InProgress,
  components: {
    ...n4Components,
  },
});

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined' && !isDevelopment) {
  ph.onFeatureFlags((featureFlags, featureVariants) => {
    // eslint-disable-next-line no-console
    console.log('ff: %o', featureVariants);
  });
  new Ping().ping(window.location.origin, (_err, ping) => {
    // eslint-disable-next-line no-console
    console.debug('ping ready: %d', ping);
    ph.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
      disable_session_recording: true,
      // person_profiles: 'identified_only',
      // Enable debug mode in development
      loaded: (phc) => {
        // eslint-disable-next-line no-console
        console.debug('ph ready');
        if (isDevelopment) {
          phc.debug();
        }
        const segment = getCookie({ name: 'ht_exp_segment' });
        phc.register({ ht_segment: segment });
        const serverPageViewId = getCookie({ name: 'ht_ph_id' });
        if (serverPageViewId) {
          phc.register({ server_pageview_id: serverPageViewId, ping });
          eraseCookie({ name: 'ht_ph_id', exactSubdomain: true });
          // eslint-disable-next-line no-console
          console.debug('recording client-page-view: %s', serverPageViewId);
          track({ event: 'client-page-view', eventType: 'performance', origin: '_app', properties: { serverPageViewId } });
        } else {
          // eslint-disable-next-line no-console
          console.debug('no server cookie');
        }
      },
    });
  });
}

/*
Plausible({
  domain: process.env.VERCEL_GIT_COMMIT_REF === 'main' ? 'www.hearingtracker.com' : 'next-staging.hearingtracker.com',
});
const { trackPageview } = Plausible();
*/

const pageview = (url) => {
  // eslint-disable-next-line no-console
  console.debug('Pageview: %s', url);
  track({ event: 'page-change', eventType: 'impression', origin: '_app' });
  /*
  if (trackPageview) {
    trackPageview();
  }
  */
  // posthog?.capture('$pageview'); capture_pageview is on by default
};

const App = ({ Component, pageProps }) => {
  const [user, setUser] = useState('loading');
  const storyblokData = pageProps;
  const router = useRouter();

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.debug('env: %s, server env: %s, api: %s', process.env.NEXT_PUBLIC_ENV, process.env.SERVER_ENV, process.env.API_URL);
    let observer;
    const fn = async () => {
      const u = await fetchApi({ path: '/user/current', fallback: {}, origin: '_app.js' });
      if (u && Object.keys(u).length) {
        setUser(u);
      } else {
        setUser(null);
      }
      pageview(window.location.pathname);
      setTimeout(() => {
        // Give it half a sec to let client-side renders finish
        observer = startTrackingObserver();
      }, [500]);
    };
    fn();
    return () => {
      if (observer) {
        stopTrackingObserver(observer);
      }
    };
  }, []);

  useEffect(() => {
    let observer = null;
    const handleRouteChange = (url) => {
      pageview(url);
      setTimeout(() => {
        observer = startTrackingObserver();
      }, [500]);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      if (observer) {
        stopTrackingObserver(observer);
      }
    };
  }, [router.events]);

  const sessionData = useMemo(
    () => ({
      setUser,
      user,
      signout: async () => {
        const result = await fetchApi({ path: 'auth/signout', method: 'delete', fallback: 'friendly', origin: '_app.js' });
        if (!result.error) {
          // do we want to notify the user if there was a problem signing them out?
          setUser(null);
        }
      },
    }),
    [user]
  );
  const storyData = useMemo(() => ({ ...storyblokData }), [storyblokData]);
  const api = useMemo(() => ({ api: pageProps.api }), [pageProps.api]);

  let content = null;
  log.silly('pageProps: %o', pageProps);
  if (/\/api/.test(router.asPath) || Component.getLayout === 'snippet') {
    content = <Component {...pageProps} />;
  } else if (Component.getLayout) {
    log.debug('Component has layout: %o', typeof Component.getLayout);
    content = Component.getLayout(<Component {...pageProps} />, pageProps);
  } else {
    log.debug('Component has no layout');
    content = (
      <main className="ht">
        <Layout {...pageProps}>
          <Component {...pageProps} />
        </Layout>
      </main>
    );
  }
  if (DISABLE_ERROR_BOUNDARY) {
    return (
      <OncePerPageContextProvider>
        <ApiDataContext.Provider value={api}>
          <SessionContext.Provider value={sessionData}>
            <AudioProvider>
              <FeatureFlagsProvider>
                <StoryblokContext.Provider value={storyData}>{content}</StoryblokContext.Provider>
              </FeatureFlagsProvider>
            </AudioProvider>
          </SessionContext.Provider>
        </ApiDataContext.Provider>
      </OncePerPageContextProvider>
    );
  }
  return (
    <GlobalErrorBoundary>
      <PostHogProvider client={ph}>
        <OncePerPageContextProvider>
          <ApiDataContext.Provider value={api}>
            <SessionContext.Provider value={sessionData}>
              <AudioProvider>
                <FeatureFlagsProvider>
                  <ErrorBoundary pageProps={pageProps}>
                    <StoryblokContext.Provider value={storyData}>{content}</StoryblokContext.Provider>
                  </ErrorBoundary>
                </FeatureFlagsProvider>
              </AudioProvider>
            </SessionContext.Provider>
          </ApiDataContext.Provider>
        </OncePerPageContextProvider>
      </PostHogProvider>
    </GlobalErrorBoundary>
  );
};

export default App;
