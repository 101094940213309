import React from 'react';

import { type SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';

import type { ABVariantStoryblok } from 'types/storyblok-component-types';

interface StoryContent {
  blocks: SbBlokData[];
}

interface Story {
  content: StoryContent;
  cv: number;
}

interface ABVariantProps {
  blok: ABVariantStoryblok;
  story: Story;
  variant: string;
}

const ABVariant: React.FC<ABVariantProps> = ({ blok, story, variant, ...props }) => {
  const [index, setIndex] = React.useState(variant === 'A' ? 0 : 1);

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('features-off') !== null) {
      setIndex(999);
      return;
    }
    if (params.get('features-on') !== null) {
      setIndex(-1);
      return;
    }
    const v = params.get('variant');
    if (v && typeof v === 'string') {
      if (/a|b/i.test(v)) {
        setIndex(v.toLowerCase() === 'a' ? 0 : 1);
        return;
      }
    }
    setIndex(variant === 'A' ? 0 : 1);
  }, [variant]);

  if (!blok.blocks?.length) {
    return null;
  }

  const blocks = index < 0 ? blok.blocks : [blok.blocks[index]];
  return blocks.filter((b) => !!b).map((b) => <StoryblokComponent blok={b} story={story} {...props} key={b._uid} {...storyblokEditable(b)} />);
};

export default ABVariant;
