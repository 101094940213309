/* eslint-disable no-console */
import React from 'react';

import ph from 'posthog-js';

import { logRemotelyClient } from 'lib/logger';
import { isDevelopment } from 'lib/ts-utils';

import Layout from './n4_layout';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidMount() {}

  componentDidCatch(error, errorInfo) {
    const stack = error?.stack;
    const flags = ph.featureFlags.getFlagPayloads();
    logRemotelyClient('%o', { source: 'error-boundary', error, stack, errorInfo, flags })
      .then(() => console.log('done logging'))
      .catch((ex) => console.error(ex));
  }

  render() {
    // Check if the error is thrown
    const { hasError, error } = this.state;
    const { children, pageProps } = this.props;
    // console.log('in error boundary, hasError: %o, error: %o, pp: %o', hasError, error, pageProps ? Object.keys(pageProps) : 'no page props');
    if (hasError && !isDevelopment) {
      return (
        <Layout {...pageProps}>
          <section className="ml-auto mr-auto flex flex-col items-center justify-center pb-[3rem] pt-[3rem]">
            <section className="w-[60%]">
              <h2 className="text-lg">Something went wrong</h2>
              <p className="mt-[1rem]">
                We are aware and are looking into it. Please try again or reload the page to see if that helps. If you continue to have problems send an e-mail
                to info@hearingtracker.com with as much information as possible. Thanks!
              </p>
              <div className="hidden">{error.message}</div>
            </section>
          </section>
        </Layout>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
