import * as React from 'react';

import NextLink from 'next/link';

import SvgIcon from 'components/ui/svg_icon';
import { cx } from 'lib/utils';

import styles from './topbar.module.scss';

const Root = React.forwardRef(({ children, isHomepage }, ref) => (
  <nav ref={ref} className={cx(styles['topbar-wrapper'], isHomepage && styles['home-page'])}>
    <div className={cx(styles.topbar, isHomepage && styles['home-page'])}>{children}</div>
  </nav>
));

Root.displayName = 'TopbarRoot';

const Search = ({ onClick, ...props }) => {
  const { isWhite, loading, ...rest } = props;
  return (
    <button {...rest} type="button" className={cx(styles.search, isWhite && styles['search--white'], loading && 'cursor-wait')} onClick={() => onClick?.()}>
      <SvgIcon icon="magnifying-glass-02" aria-hidden />
    </button>
  );
};

const Close = ({ onClick, className, ...props }) => (
  <button {...props} type="button" className={cx(styles.search, styles['search--close'], className)} onClick={() => onClick?.()}>
    <SvgIcon icon="xmark-regular" aria-hidden />
  </button>
);

const Toggler = ({ className, ...props }) => {
  const { isWhite, ...rest } = props;
  return (
    <button type="button" aria-label="toggle navigation" className={cx(styles.hamburger, className, isWhite && styles['hamburger--white'])} {...rest}>
      <SvgIcon icon="bars" aria-hidden />
    </button>
  );
};

const Logo = ({ ...props }) => {
  const { isWhite } = props;
  return <NextLink prefetch={false} href="/" className={cx(styles.logo, isWhite && styles['logo--white'])} alt="HearingTracker Logo" />;
};

export const Topbar = Object.assign(Root, {
  Logo,
  Search,
  Close,
  Toggler,
});
