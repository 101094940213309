import { storyblokEditable } from '@storyblok/react';
import NextLink from 'next/link';

import ImageWrapper from 'components/common-n4/image';
import SvgIcon from 'components/ui/svg_icon';
import logger from 'lib/logger';
import { cx, normalizeUrl } from 'lib/utils';

const log = logger({ category: 'n4/CallToAction' });

// TODO: remove this once we verify we've updated all CTA bloks
const legacyAnalytics = { category: 'paid-marketing', label: 'sennheiser' };

const origin = 'cms/call-to_action';

const CallToAction = ({ blok, story, ...props }) => {
  log.debug('blok: %o', { blok, props });
  const { image } = blok;
  const isHomepage = props.title === 'Home';
  const url = blok?.button_link?.cached_url;
  const cardPosition = blok.card_position || 'left';
  const ctaSplit = cardPosition === 'split-right';

  log.debug('url: %o', url);

  if (!url) {
    log.warn('No url, returning');
    return <div>No url: {JSON.stringify(blok)}</div>;
  }

  return (
    <section
      className={cx(
        blok.className,
        blok.component,
        isHomepage ? '!m-0 ' : ' h-full w-full max-w-[1314px]',
        'relative  flex flex-col ',
        cardPosition === 'right' ? 'm-0 gap-4 px-[16px]  pt-[48px] md:px-[48px] md:pb-[8px] desktop-xl:px-[96px] desktop-xl:pb-0' : 'mx-auto ',
        ctaSplit
          ? `gap-[8px] px-[16px] pb-[24px] 
          md:flex md:flex-row md:gap-[16px] 
          md:px-[48px] md:pb-[32px] 
          desktop-xl:gap-8 desktop-xl:px-[96px] desktop-xl:pb-[32px]`
          : ''
      )}
      {...storyblokEditable(blok)}
    >
      <figure
        className={cx(
          'relative m-0 flex overflow-hidden rounded-[20px] bg-gray-light ',
          cardPosition === 'right' ? ' h-[600px] max-h-[900px]  w-full md:h-[720px] lg:h-[720px] desktop-xl:h-[800px] ' : '  w-full md:max-h-full ',
          ctaSplit
            ? 'h-[280px] w-full md:h-[600px] md:w-[55%] md:min-w-[55%] lg:min-w-[60%] desktop-xl:h-[800px] desktop-xl:rounded-[30px]'
            : 'h-[45rem] max-h-[37.5rem]'
        )}
      >
        <ImageWrapper
          image={image}
          imgProps={{
            alt: image.alt || blok.title,
            className: cardPosition === 'right' ? 'object-cover w-full h-full h-[600px] relative' : 'object-cover w-full h-full',
          }}
          origin={origin}
        />
      </figure>
      <NextLink
        prefetch={false}
        href={normalizeUrl({ url, origin })}
        data-track
        data-event-name="call-to-action"
        data-event-origin={origin}
        data-analytics-category={blok.analytics_category || legacyAnalytics.category}
        data-analytics-label={blok.analytics_label || legacyAnalytics.label}
        className={cx(
          'absolute z-[2] flex flex-col justify-between self-stretch overflow-hidden rounded-[1.25rem] p-[16px]   ',
          cardPosition === 'right'
            ? `h-[600px] max-h-[900px] w-[calc(100%-32px)]  shrink-[2] items-start 
            p-[16px] md:h-[720px] md:max-w-[calc(100%-96px)] 
            lg:h-[720px] lg:w-full desktop-xl:h-[800px] desktop-xl:w-[calc(100%-192px)]`
            : '  w-full ',
          ctaSplit
            ? `relative h-[360px] rounded-[20px] bg-lime 
            p-[24px] md:h-[600px] md:min-w-[35%] 
            md:p-[24px] lg:min-w-[30%] 
            desktop-xl:h-[800px] desktop-xl:rounded-[30px] 
            desktop-xl:p-[35px] `
            : '  ',
          cardPosition !== 'right' && !ctaSplit && 'h-full md:p-[48px]',
          cardPosition === 'right' && !ctaSplit && 'md:p-[48px] '
        )}
      >
        <span
          className={cx(
            'flex w-full max-w-[48rem] justify-start   font-normal not-italic leading-6 tracking-[-0.01rem] text-white sm:p-0',
            cardPosition === 'right' ? 'p-[12px]  text-[16px]  !font-[500] leading-[140%] tracking-[-0.48px]' : ' text-md',
            ctaSplit ? 'hidden' : '',
            cardPosition !== 'right' && !ctaSplit && 'pl-4 pt-4 '
          )}
        >
          {blok.small_text}
        </span>
        <span
          className={cx(
            ctaSplit
              ? `!desktop-xl:mb-[47px] absolute z-[1] mb-[16px] flex h-[36px] w-[97px] items-center 
              justify-center gap-[8px] rounded-[60px]
              bg-sky-50 px-[16px] py-[8px] text-[14px] font-medium 
              leading-[140%] tracking-[-0.42px] text-navy 
              sm:mb-[32px] desktop-xl:h-[52px] 
              desktop-xl:w-[142px] desktop-xl:gap-3 
              desktop-xl:px-[24px] desktop-xl:py-[12px] desktop-xl:text-[21px] desktop-xl:tracking-[-0.615px]`
              : 'hidden'
          )}
        >
          Sponsored
        </span>
        {/* CONTENT_DATA */}
        <section
          className={cx(
            'flex h-fit  w-full max-w-none flex-col gap-8 self-stretch rounded-[1.25rem] xs:justify-items-end md:max-w-[35rem] md:p-[32px]',
            cardPosition === 'right' ? 'gap-[32px] rounded-[20px] px-[25px] py-[24px] md:absolute md:bottom-[48px] md:right-[48px]' : ' ',
            ctaSplit ? ' relative flex h-full gap-0 bg-lime pt-[48px]  md:px-0 md:pb-0 md:pt-[64px] desktop-xl:pt-[96px]' : 'bg-white p-6'
          )}
        >
          {/* ABOUT */}
          <section
            className={cx(
              'flex flex-col  justify-between  self-stretch ',
              cardPosition === 'right' ? 'gap-[16px]' : '',
              ctaSplit ? 'gap-0 md:w-[102%]' : 'gap-4 sm:gap-[16px]'
            )}
          >
            <span
              className={cx(
                '  not-italic   text-navy   ',
                cardPosition === 'right'
                  ? `w-full text-[32px] font-semibold 
                  leading-[120%] tracking-[-0.96px] 
                  md:text-[48px] md:leading-[110%] 
                  md:tracking-[-1.44px] `
                  : '   sm:font-[450] sm:leading-[3.6rem] sm:tracking-[-0.03rem]  ',
                cx(
                  ctaSplit
                    ? ` mb-[12px] text-[16px] font-medium leading-[140%] tracking-[-0.48px] 
                    md:mb-[16px] md:text-[20px] 
                    md:font-semibold md:leading-[120%] 
                    md:tracking-[-.6px] desktop-xl:mb-[24px] desktop-xl:text-[30px] 
                    desktop-xl:tracking-[-0.879px]`
                    : 'text-[2rem] font-[400]  tracking-[-0.02rem] md:text-[3rem]'
                )
              )}
            >
              {blok.title}
            </span>
            <p
              className={cx(
                '  not-italic  text-navy',
                cardPosition === 'right' ? 'w-full text-[18px] leading-[140%] tracking-[-0.54px]' : 'max-w-[30rem]',
                ctaSplit
                  ? `mb-[50px] text-[32px] font-[600] leading-[120%] tracking-[-0.96px] md:mb-0 
                  md:text-[40px] lg:tracking-[-1.2px] 
                  desktop-xl:text-[59px] desktop-xl:tracking-[-1.757px]`
                  : '  font-normal leading-[1.5] tracking-[-0.01125rem] '
              )}
            >
              {blok.description}
            </p>
          </section>
          {/* ACTION */}
          <section className={cx('flex items-center justify-end gap-[16px]', ctaSplit ? 'absolute bottom-0 right-0 desktop-xl:gap-[27px]' : 'flex')}>
            <span
              className={cx(
                'not-italic text-navy',
                cardPosition === 'right' && 'text-[16px] font-[500] leading-[140%] tracking-[-0.48px] md:text-[18px] md:font-[400] md:tracking-[-0.54px]',
                ctaSplit
                  ? [
                      'text-[16px]',
                      'font-[500]',
                      'leading-[140%]',
                      'tracking-[-0.48px]',
                      'lg:tracking-[-0.54px]',
                      'md:text-[18px]',
                      'desktop-xl:text-[27px]',
                      'desktop-xl:tracking-[-0.791px]',
                    ]
                  : ['font-[330]', 'leading-6', 'tracking-[-0.01rem]', 'sm:leading-[1.6875rem]', 'sm:tracking-[-0.01125rem]', 'md:text-[1.125rem]']
              )}
            >
              {blok.button_text}
            </span>
            {/* OFFER */}
            <section
              className={cx(
                'relative flex  items-center justify-center rounded-full bg-navy ',
                cardPosition === 'right' ? 'h-[48px] w-[48px] gap-[6px] sm:h-[56px] sm:w-[56px] sm:gap-[8px] lg:gap-[16px]' : 'h-[3.625rem] w-[3.625rem]',
                ctaSplit
                  ? 'h-[48px] w-[48px] gap-[7px] rounded-[51px] sm:h-[56px] sm:w-[56px] sm:gap-2 desktop-xl:h-[82px] desktop-xl:w-[82px] desktop-xl:gap-3'
                  : ''
              )}
            >
              <SvgIcon
                icon="arrow-right-regular"
                className={cx(
                  ' absolute bottom-0 left-0 right-0 top-0 m-auto',
                  ' [filter:invert(95%)_sepia(100%)_saturate(0%)_hue-rotate(78deg)_brightness(104%)_contrast(106%)]',
                  cardPosition === 'right' ? 'h-[20.6px] w-[20.6px] md:h-[24px] md:w-[24px]' : 'h-[1.188rem] w-[16px] ',
                  ctaSplit ? 'h-[20px] w-[20px] md:h-[24px] md:w-[24px] desktop-xl:h-[35px] desktop-xl:w-[35px]' : ''
                )}
              />
            </section>
          </section>
        </section>
      </NextLink>
    </section>
  );
};

export default CallToAction;
