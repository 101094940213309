import React from 'react';

import { type SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';

import { FeatureFlagVariantStoryblok } from 'types/storyblok-component-types';

interface StoryContent {
  blocks: SbBlokData[];
}

interface Story {
  content: StoryContent;
  cv: number;
}

interface FeatureFlagProps {
  blok: FeatureFlagVariantStoryblok;
  story: Story;
}

const FeatureFlagVariant: React.FC<FeatureFlagProps> = ({ blok, story, ...props }) => {
  return blok.blocks.map((b) => <StoryblokComponent blok={b} key={b._uid} story={story} {...props} {...storyblokEditable(b)} />);
};

export default FeatureFlagVariant;
